import styled from "styled-components";

export const DimensionListContainer = styled.div`
  margin: 2% 0;
  @media(max-width: 850px) {
    overflow: scroll;
  }

  .dimension-list-delete-button {
    cursor: pointer;
    margin-right: 15px;
    
    svg {
      background: none;
      fill: ${({ theme }) => theme.colors.primary};
      
    }
  }

  .dimension-list-editar-button {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    opacity: 0.9;
    background: ${({ theme }) => theme.colors.softGray};
    padding: 5px 15px;
    border-radius: 20px;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
`;

export const DimensionListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 500;
  }
  div {
    display: flex;
  }

`;
export const DimensionListContent = styled.table`
  display: flex;
  min-width: 850px;

  .dimension-list-delete-button {
    position: absolute;
    left: -30px;
    cursor: pointer;
    margin-right: 15px;
    
    svg {
      background: none;
      fill: ${({ theme }) => theme.colors.primary};
      
    }
  }
  

  .listing-header {
    width: 100%;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGray};
    height: 60px;
    padding: 0 20px;
    th {
      background-color: ${({ theme }) => theme.colors.lightGray};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }
  }
  `;

export const DimensionLine = styled.tr`
  width: 100%;

  
`;

export const DimensionThumbnail = styled.div<{url?: string}>`
  width: 158px;
  height: 67px;
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

`;