import styled from "styled-components";

export const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  font-size: 1.1em;
  @media(max-width: 650px) {
    height: 75vh;
  }
`;

interface SlideDto {
  isActive: boolean;
  backgroundImage: string;
}

export const Slide = styled.div<SlideDto>`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity 0.5s ease;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5em; /* Adiciona espaço no topo */
`;

export const Title = styled.h2`
  color: #1351B4;
  text-align: center;
`;