import { TableColumnsType } from "antd";
import { IdeiaDtoRelatedItem } from "../../dto/IdeiaDtoRelatedItem";

const searchedListColumns: TableColumnsType<IdeiaDtoRelatedItem> = [
  {
    title: 'ID da Ideia',
    dataIndex: 'ideaId',
  },
  {
    title: 'Título',
    dataIndex: 'title',
  },
  {
    title: 'Resumo',
    dataIndex: 'shortDescription',
  },
  {
    title: 'Autor',
    dataIndex: 'authorName',
  },
  {
    title: 'Ações',
    dataIndex: 'action',
  },
]

export default searchedListColumns;