import React, { useState } from 'react';
import { PaginationContainer as StyledPaginationContainer } from "./styles";
import { IPageData } from "../../dto/PageData";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface PaginationProps {
  options: number[];
  pageOptions: number[];
  perPage: number;
  setPerPage: (value: number) => void;
  setPage: (value: number) => void;
  data: {
    itemsPerPage: number;
    totalItems: number;
  };
}

const PaginationContainer = (props: PaginationProps) => {
  const { options, pageOptions, perPage, setPerPage, setPage, data } = props;

  const RenderPerPageOptions = () => {
    if (!options || options.length === 0) {
      return null;
    }

    return (
      <select onChange={(e) => setPerPage(Number(e.target.value))}>
        {options.map((option) => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    );
  };

  const RenderPageOptions = () => {
    if (!pageOptions || pageOptions.length === 0) {
      return null;
    }

    return (
      <select onChange={(e) => setPage(Number(e.target.value))}>
        {pageOptions.map((option) => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    );
  };

  return (
    <div>
      <div>
        <div>
          Exibir <RenderPerPageOptions />{" "}
        </div>
        <div>
          {perPage && data.itemsPerPage && data.totalItems ? (
            <>
              <span>
                1-{perPage} de {data.totalItems}{" "}
                {`ite${data.totalItems > 1 ? "ns" : "m"}`}
              </span>
            </>
          ) : null}
        </div>
      </div>
      <div>
        <div>
          Página <RenderPageOptions />
        </div>
      </div>
    </div>
  );
};

interface IPaginationProps {
	data: IPageData<any>;
	request(page: number, itemsPerPage: number): Promise<void>;
}

function Pagination(props: IPaginationProps) {
	const [perPage, setPerPage] = useState(10);

	React.useEffect(() => {
		if (props.data.itemsPerPage && props.data.itemsPerPage !== perPage) {
			setPerPage(props.data.itemsPerPage);
		}
	}, [props.data.itemsPerPage]);

	async function requireUserList(page: number, perPage: number) {
		try {
			await props.request(page, perPage);
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnChangePerPage(e: React.ChangeEvent<HTMLSelectElement>) {
		if (e.target.value && Number(e.target.value) === perPage) return;
		setPerPage(Number(e.target.value));
		requireUserList(1, Number(e.target.value));
	}

	function handleOnChangePage(e: React.ChangeEvent<HTMLSelectElement>) {
		if (e.target.value && props.data.currentPage === Number(e.target.value))
			return;
		requireUserList(Number(e.target.value), perPage);
	}

	function handleNextPage() {
		if (
			props.data.currentPage &&
			props.data.currentPage < props.data.totalPages
		) {
			requireUserList(props.data.currentPage + 1, perPage);
		}
	}

	function handlePreviousPage() {
		if (props.data.currentPage && props.data.currentPage > 1) {
			requireUserList(props.data.currentPage - 1, perPage);
		}
	}

	const options = [5];
	for (let i = 10; i <= props.data.totalItems; i += 10) {
		options.push(i);
	}

	const pageOptions = Array.from(
		{ length: props.data.totalPages },
		(_, i) => i + 1
	);

	return (
		<StyledPaginationContainer>
			<PaginationContainer
				options={options}
				pageOptions={pageOptions}
				perPage={perPage}
				setPerPage={setPerPage}
				setPage={(page) => requireUserList(page, perPage)}
				data={{
					itemsPerPage: props.data.itemsPerPage,
					totalItems: props.data.totalItems,
				}}
			/>
			<div className="pagination-controller">
				<FaChevronLeft
					title="Voltar"
					onClick={handlePreviousPage}
					aria-disabled={
						props.data.currentPage && props.data.currentPage === 1
							? true
							: false
					}
				/>
				<FaChevronRight
					title="Avançar"
					onClick={handleNextPage}
					aria-disabled={
						props.data.currentPage &&
						props.data.totalPages &&
						props.data.currentPage === props.data.totalPages
							? true
							: false
					}
				/>
			</div>
		</StyledPaginationContainer>
	);
}

export default Pagination;
