import { Progress } from "antd/lib";
import React from "react";

interface RatingProgressBarProps {
  rating: number;
}

function RatingProgressBar({ rating }: RatingProgressBarProps) {
  const ratingPercentage = (rating / 5) * 100;
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const adjustedWidth = width > 1000 ? 100 : (width / 1000) * 100;

  return (
    <div className="rating-progress-bar">
      <Progress className={"progress-bar"} percent={ratingPercentage} size={[400, 25]} showInfo={false} strokeColor="#1351b4"/>
      <b>{rating.toFixed(1)} de 5.0</b>
    </div>
  );
}

export default RatingProgressBar;