import { FaHome, FaPaste, FaUser } from "react-icons/fa";
import { ROLES } from "../../../../../guard/Roles";

const MenuItems = [
	{
		title: "Explorar Ideias",
		path: "/",
		icon: <FaHome />,
		disabled: false,
	},
	{
		title: "Administração",
		path: "/administracao",
		protected: [ROLES.ADMIN],
		icon: <FaPaste />,
	},
];

export default MenuItems;
