import React from "react";
import { ViewModalContainer, ViewModalSection } from "./styles";
import { useIdeia } from "../../../../shared/context/ideia/provider";
import { ConfigProvider, Modal, Tabs } from "antd";
import PermissionValidator from "../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../shared/guard/Permissions";
import { TabItems } from "./constants/tabs";
import DetailsView from "./components/Details";
import DefaultButton from "../../../../shared/util/DefaultButton";
import ptBR from 'antd/lib/locale/pt_BR';
import CommentsView from "../NormalView/components/Comments";


export interface UserValue {
  label: string;
  value: string;
}

function ViewModal(): JSX.Element {
  const permissionValidator = new PermissionValidator();
  const {
    openView: openModal,
    setOpenView: setOpenModal,
    ideia,
    setOpenEdit,
  } = useIdeia();

  React.useEffect(() => {
    if (!permissionValidator.hasPermission(PERMISSIONS.INSERIR_IDEIA)) {
      setOpenModal(false);
    }
  }, []);

  const [tabKey, setTabKey] = React.useState("details");

  function onInit() {}

  React.useEffect(() => {
    if (openModal) {
      onInit();
    }
  }, [openModal]);

  function onTabChange(key: string) {
    setTabKey(key);
  }

  return (
    <ViewModalContainer>
      <Modal
        title={<h2>{ideia.title}</h2>}
        open={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        maskClosable={false}
        width={1280}
        footer={[
          <DefaultButton
            key={"back"}
            width="150px"
            height="35px"
            fontSize="1em"
            withOutBackground={true}
            params={{
              type: "button",
              onClick: () => setOpenEdit(true),
              style: { marginRight: "10px" }
            }}
          >
            Editar
          </DefaultButton>,
          <DefaultButton
            key={"submit"}
            width="150px"
            height="35px"
            fontSize="1em"
            params={{
              type: "button",
              onClick: () => setOpenModal(false),
            }}
          >
            Fechar
          </DefaultButton>,
        ]}
        centered
      >
        <ConfigProvider locale={ptBR}>
          <ViewModalSection>
            <Tabs defaultActiveKey="1" items={TabItems} onChange={onTabChange} />
            {tabKey === "details" && <DetailsView idea={ideia} />}
            {tabKey === "comments" && <CommentsView idea={ideia} />}
          </ViewModalSection>
        </ConfigProvider>
      </Modal>
    </ViewModalContainer>
  );
}

export default ViewModal;