import styled from "styled-components";
import { DatePicker, Input, Select } from 'antd';

export const IdeiaContainer = styled.div`
    background: none;
    margin: 2rem 10%;

    .filtros-expansiveis {
      @media (max-width: 933px) {
        flex-wrap: wrap;
      }
    }

    @media (max-width: 933px) {
      margin: 2rem 2%;
    }

    .search-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }

    .search-input {
        width: 79.688rem;

        @media (max-width: 1200px) {
            width: 60rem;
        }

        @media (max-width: 992px) {
            width: 45rem;
        }

        @media (max-width: 768px) {
            width: 30rem;
        }

        @media (max-width: 576px) {
            width: 20rem;
        }

        @media (max-width: 400px) {
            width: 15rem;
        }
    }

    .search-button {
        margin-left: 10px;
        color: #666;

        @media (max-width: 600px) {
            margin-left: 0;
            margin-top: 2px;
        }
    }

    .icon-container {
        display: flex;
        align-items: center;
        padding: 1.5rem;
    }

    .icon {
        transition: color 0.3s;
        color: #1351b4;
    }

    .icon-container:hover .icon {
        color: #1677ff;
    }
`;

export const Button = styled.button`
    width: 85px;
    display: flex;
    height: 85px;
    background-color: #273373;
    border: 0;
    padding: 1rem;
    transition: transform 0.2s;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;


    &:hover {
        background-color: #1351b4;
        transform: scale(1.1);
    }
`;

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  height: 40px;

  &:hover {
    border-color: #40a9ff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  }

  .ant-picker-input > input {
    font-size: 1rem;
  }

  .ant-picker-suffix {
    color: #40a9ff;
  }

  .ant-picker-clear {
    color: #ff4d4f;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  height: 40px;
  .ant-select-selector {
    height: 100% !important;
    display: flex;
    align-items: center;
  }
`;

export const StyledInput = styled(Input)`
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  max-width: 900px;
  width: 100%;
  flex: 1;

  &:hover {
    border-color: #40a9ff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  }
`;