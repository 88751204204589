import React from "react";
import { Table, TablePaginationConfig } from "antd";
import { IdeiaDtoRelatedItem } from "../dto/IdeiaDtoRelatedItem";
import searchedListColumns from "./constants/searchedListColumns";

interface SearchedListProps {
  data: IdeiaDtoRelatedItem[];
  handleTableChange: (pagination: TablePaginationConfig, filters: any, sorter: any) => void;
  pagination: false | TablePaginationConfig | undefined;
}

const SearchedList: React.FC<SearchedListProps> = ({ data, handleTableChange, pagination }) => {
  return (
    <section>
      <Table<IdeiaDtoRelatedItem>
        columns={searchedListColumns}
        dataSource={data}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </section>
  );
};

export default SearchedList;