import styled from "styled-components";

export const ViewModalContainer = styled.div`
  .normal-view-modal {
    height: 100%;
    max-height: 600px;
  }

`;

export const ViewModalSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-right: 5px;
  overflow-y: auto;
  height: 600px;

  label {
    font-size: 14.4px;
    margin-bottom: 3px;
  }
`;

export const ViewImage = styled.div<{ src: string }>`
  width: 100%;
  height: 300px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ViewMetaInfo = styled.div``;

export const ViewH2 = styled.h2`
  margin-bottom: 22px;
`;

export const ViewH4 = styled.h4`
  margin-bottom: 22px;
`;