import { Flex, Tag } from "antd";
import { Progress } from "antd/lib";
import React from "react";
import { RatingDto } from "../../../../../../../shared/dto/RatingDto";

interface RatingProgressBarProps {
  rating: RatingDto;
}

function RatingProgressBar({ rating }: RatingProgressBarProps) {
  const [width, setWidth] = React.useState(window.innerWidth);
  const ratingScore = React.useMemo(() => {
    return rating?.score || 0;
  }, [rating]);
  const ratingPercentage = (ratingScore / 5) * 100;

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className="rating-progress-bar">
      <Progress className={"progress-bar"} percent={ratingPercentage} size={{height: 15}} showInfo={false} strokeColor="#1351b4"/>
      <Flex gap={12}>
        <b>
            <span>{ratingScore.toFixed(1)} de 5.0</span>
            <span style={{fontSize: 12, textTransform: "capitalize"}}>
                {!!rating && rating.status === 'RASCUNHO' && (
                  <Tag style={{
                    margin: "0 12px",
                    backgroundColor: "#fba002",
                    color: "#fff",
                    borderRadius: "12px"}} >
                  RASCUNHO
                  </Tag>
                )}
              </span>
          </b>
      </Flex>
    </div>
  );
}

export default RatingProgressBar;