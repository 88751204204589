import { BellOutlined } from '@ant-design/icons';
import { IUsuarioSimplificado } from '../../../../dto/UsuarioSimplificado';
import UserProvider from '../../../../provider/UserProvider';
import React from 'react';
import { NotificationDto } from '../../../../dto/NotificationDto';
import { Dropdown, List, Button, notification } from 'antd';
import { Link } from 'react-router-dom';

interface NotificationBellProps {
  user: IUsuarioSimplificado
}

function NotificationBell(params: NotificationBellProps): JSX.Element {
  const { user } = params;
  const [notifications, setNotifications] = React.useState<NotificationDto[]>([]);
  const [visible, setVisible] = React.useState(false);

  const userProvider = new UserProvider();

  async function getNotifications() {
    try {
      const response = await userProvider.getLastsNotifications();
      setNotifications(response);
    } catch (error) {
      console.error("Erro ao buscar notificações:", error);
    }
  }

  React.useEffect(() => {
    getNotifications();
    const interval = setInterval(() => {
      getNotifications();
    }, 5000);

    return () => clearInterval(interval); 
  }, []);

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const notificationList = (
    <div style={{ width: 300, maxHeight: 400, overflowY: 'auto' }}>
      <List
      dataSource={notifications}
      locale={{ emptyText: 'Sem notificações' }}
      style={{
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      }}
      renderItem={item => (
        <List.Item key={item.notificationId} style={{
        padding: '10px 15px',
        borderBottom: '1px solid #f0f0f0',
        }}>
        {!!item.event && !!item.event.title && <List.Item.Meta
          title={<Link to={`/?ideia=${item.event.ideaId}`}>{item.event.title}</Link>}
        />}
        </List.Item>
      )}
      />
    </div>
  );

  return (
    <Dropdown
      overlay={notificationList}
      trigger={['click']}
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <BellOutlined
          style={{
            fontSize: '24px',
            color: 'blue',
            cursor: 'pointer',
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = 'darkblue')}
          onMouseLeave={(e) => (e.currentTarget.style.color = 'blue')}
        />
        {notifications.length > 0 && <span
          style={{
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            background: 'red',
            borderRadius: '50%',
            color: 'white',
            padding: '2px 6px',
            fontSize: '12px',
          }}
        >
          {notifications.length}
        </span>}
      </div>
    </Dropdown>
  );
}

export default NotificationBell;