import styled from "styled-components";

export const RatingContainer = styled.div`
  .rating-progress-bar {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    b {
      margin-left: 10px;
      font-size: 16px;
      width: 100px;
    }
  }
  .head-rating {
    @media (max-width: 800px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

export const RatingRight = styled.div`
  flex: 1;
`;