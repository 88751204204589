import styled from "styled-components";

export const CardIdeas = styled.div`
    width: 300px;
    border-radius: 10px;
		background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;

    @media (max-width: 536px) {
      width: 100%;
      height: 450px;
      margin: 0 15px;
    }

    &:hover {
      transform: scale(1.05);
    }
`;

export const CardTitle = styled.div`
    background: #fff;
    min-height: 44px;
    color: #000;
    font-weight: bold;
		font-size: 1rem;
		margin: 0.6rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const CardContent = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 28%;
  padding: 0 1.5rem;
  transition: height 0.5s ease;

  @media (max-width: 536px) {
      position: relative;
    }

  ${CardIdeas}:hover & {
    height: 90%;
  }

  footer {
    margin: 10px 0;
  }

  section {
    margin-top: 26px;
    font-size: 13.44px;
    height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const CardImage = styled.img`
    width: 100%;
    height: 220px;
    object-fit: cover;
`;