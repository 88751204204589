export const TabItems = [
  {
    label: 'Detalhes',
    key: 'details'
  },
  {
    label: 'Comentários',
    key: 'comments'
  },
  {
    label: 'Avaliação',
    key: 'rating'
  },
  {
    label: 'Ideias Relacionadas',
    key: 'related'
  },
]
