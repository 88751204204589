import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline:0;
        box-sizing:border-box;
        font-family: 'Rawline', sans-serif; 
        h1, h2, h3, h4, h5, h6 {
            color: ${props => props.theme.colors.headerText};
        }
    }

    html {
        scroll-behavior: smooth;
        background: ${props => props.theme.colors.background};
        color: ${props => props.theme.colors.text};
    }

    #root {
        margin:0 auto;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .input-label, .input-message {
        font-size: 0.9em;
    }
    .input-label {
        font-weight: 600;
    }

    .skeleton-loading {
        position: relative;
        overflow: hidden;
        border: 0 !important;
        background: none;
    }

    .skeleton-loading::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #e0e0e0 25%, #d3d3d3 50%, #e0e0e0 75%);
        background-size: 200% 100%;
        animation: shimmer 1s infinite linear;
    }

  .list-delete-button {
    cursor: pointer;
    margin-right: 15px;
    
    svg {
      background: none;
      fill: ${({ theme }) => theme.colors.primary};
      
    }
  }

  .list-editar-button {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    opacity: 0.9;
    background: ${({ theme }) => theme.colors.softGray};
    padding: 5px 15px;
    border-radius: 20px;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  #criar-nova-ideia-btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    bottom: 8%;
    right: 8%;
    width: 115px;
    height: 115px;
    gap: 10px;
    transition: all 0.3s ease-in-out;

    @media (max-width: 768px) {
        bottom: 5%;
        right: 5%;
        transform: scale(0.9);

    }

    #plus-icon {
        width: 24px;
        height: 24px;
        margin-top: 20px;
        margin-right: -5px;
        font-weight: 800;
    }

    #lightbulb-icon {
        height: 50px;
    }
   
    &:hover {
        #criar-nova-ideia-span {
            opacity: 1;
        }
    }
    #criar-nova-ideia-span {
        position: absolute;
        bottom: -15px;
        padding: 2px 4px;
        font-size: 0.8em;
        background-color: ${({ theme }) => theme.colors.text};
        color: ${({ theme }) => theme.colors.background};
        border-radius: 4px;
        opacity: 0.5;
        transition: opacity 0.3s ease-in-out;

    }
    button {
        svg {
            font-size: 2.5em;
        }
        width: 100px;
        height: 100px;
    }
  }

    @keyframes shimmer {
        0% { background-position: -100% 0; }
        100% { background-position: 100% 0; }
    }

    .link-disabled {
        pointer-events: none;
        color: #d3d3d3 !important;
        cursor: not-allowed;
    }

    .red-button {
        background-color: red;
        color: ${({ theme }) => theme.colors.background};
        border: none;
        border-radius: 30px;
        font-size: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        cursor: pointer;
    }

    .edit-user-success-step {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 4%;
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        h2 {
            font-size: 1.2em;
            font-weight: 600;
        }
    
    }

    .div-divider {
        width: 100%;
        height: 1px;
        background-color: #ccc;
        margin: 2% 0;
    }

    .roletag {
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 0.9em;
        font-weight: 600;
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.background};
    }

    .footer {
        margin-top: auto;
    }
`