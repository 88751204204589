import { Flex, Select, Input } from "antd";
import { DimensionDto } from "../../../../../../../shared/dto/DimensionDto";
import React, { useState, useEffect } from "react";
import PermissionValidator from "../../../../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../../../../shared/guard/Permissions";
import { RatingPayload } from "../../..";
import { CreateRatingItemDTO, RatingDto } from "../../../../../../../shared/dto/RatingDto";
import { IdeiaDto } from "../../../../../../../shared/dto/IdeiaDto";

const { TextArea } = Input;

interface RatingDimensionItemsProps {
  dimensions: DimensionDto[];
  ratingPayload: RatingPayload;
  setRatingPayload: (value: RatingPayload) => void;
  rating: RatingDto;
  idea: IdeiaDto;
}

function RatingDimensionItems(props: RatingDimensionItemsProps) {
  const { dimensions, ratingPayload, setRatingPayload, rating, idea } = props;
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: number }>({});
  const [comments, setComments] = useState<{ [key: string]: string }>({});
  const permissionValidator = new PermissionValidator();

  useEffect(() => {
    if (rating && rating.dataConclusaoAvaliacao === null) {
      const initialSelectedValues: { [key: string]: number } = {};
      const initialComments: { [key: string]: string } = {};

      rating.ratingItems.forEach((item) => {
        initialSelectedValues[item.dimensionId] = Number(item.criteriaId);
        initialComments[item.dimensionId] = item.description;
      });

      setSelectedValues(initialSelectedValues);
      setComments(initialComments);
    } else {
      setSelectedValues({});
      setComments({});
    }
  }, [rating]);

  const handleSelectChange = (dimensionId: string, value: number) => {
    setSelectedValues((prev) => ({ ...prev, [dimensionId]: value }));
  };

  const handleCommentChange = (dimensionId: string, event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments((prev) => ({ ...prev, [dimensionId]: event.target.value }));
  };

  useEffect(() => {
    if (!idea || !dimensions) return;
    const items = dimensions.map((dimension) => {
      const criteria = dimension.categories?.find((category) => Number(category.dimensionCategoryId) === selectedValues[dimension.dimensionId as string]);
      const ratingItemId = !!rating && !!rating.ratingItems ? rating.ratingItems.find((item) => item.dimensionId === dimension.dimensionId)?.ratingItemId : undefined;

      if (criteria === undefined) return {} as CreateRatingItemDTO;

      const payload: CreateRatingItemDTO = {
        dimensionId: dimension.dimensionId as string,
        criteriaId: criteria?.dimensionCategoryId as string,
        ratingItemId: ratingItemId as string,
        ratingId: !!rating && rating.ratingId ? rating.ratingId as string : undefined,
        description: comments[dimension.dimensionId as string],
      };
      return payload;
    });
    if (!items || !items.length) return;

    setRatingPayload({
      ...ratingPayload,
      items: items.filter((item) => !!item.criteriaId),
      ideaId: idea.ideaId as string,
    });
  }, [selectedValues, comments]);

  if (!permissionValidator.hasPermission(PERMISSIONS.AVALIAR_IDEIAS)) return <></>;

  return (
    <div className="rating-dimension-items">
      {dimensions.map((item) => (
        <Flex key={item.dimensionId} style={{ flexDirection: "column", margin: "15px 0" }}>
          <h4 style={{ textTransform: "uppercase" }}>{item.title}</h4>
          <span>{item.description}</span>
          <Select
            placeholder="Selecione um critério"
            onChange={(value) => handleSelectChange(item.dimensionId as string, value)}
            value={selectedValues[item.dimensionId as string]}
            style={{ width: "100%", marginBottom: "10px" }}
          >
            {item.categories?.map((category) => (
              <Select.Option key={category.value} value={category.dimensionCategoryId}>
                {category.title}
              </Select.Option>
            ))}
          </Select>
          {selectedValues[item.dimensionId as string] !== undefined && (
            <TextArea
              placeholder="Comentário"
              value={comments[item.dimensionId as string]}
              onChange={(event) => handleCommentChange(item.dimensionId as string, event)}
              maxLength={350}
              showCount={true}
            />
          )}
        </Flex>
      ))}
    </div>
  );
}

export default RatingDimensionItems;