import React, { useEffect, useState } from "react";
import {
	CategoryListContainer,
	CategoryListHeader,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CategoryDto } from "../../../../../../shared/dto/CategoryDto";
import { IPageData } from "../../../../../../shared/dto/PageData";
import sortList from "../../../../../../shared/util/Order";
import CategoryProvider from "../../../../../../shared/provider/CategoryProvider";
import AddCategory from "../AddCategory";
import EditCategory from "../EditCategory";
import DeleteCategory from "../DeleteCategory";
import { Table } from "antd";
import { CategoryItem } from "../../../../../../shared/dto/CategoryItem";
import customLocale from "../../../../../../shared/constants/customLocale";
import columns from "./config/columns";
import mapCategoryDtoListToItems from "./utils/mapDtoToItem";

function CategoryListItem({
	openAddCategory,
	setOpenAddCategory,
}: {
	readonly openAddCategory: boolean;
	readonly setOpenAddCategory: React.Dispatch<boolean>;
}): JSX.Element {
	const [sortDirection] = React.useState<"asc" | "desc">();
	const [sortKey] = React.useState<string>();
	const [editCategory, setEditCategory] = React.useState(false);
	const [deleteCategory, setDeleteCategory] = React.useState(false);
	const [selectedCategoryForEdit, setSelectedCategoryForEdit] = React.useState(
		{} as CategoryDto
	);
	const [selectedCategoryForDelete, setSelectedCategoryForDelete] =
		React.useState({} as CategoryDto);

	const [response, setResponse] = useState<IPageData<Array<CategoryDto>>>(
		{} as IPageData<Array<CategoryDto>>
	);
	const [list, setList] = useState<CategoryItem[]>([]);

	const categoryProvider = new CategoryProvider();
	const navigate = useNavigate();

	function getPreviousSelectedCategoryForEdit(data: CategoryDto[]) {
		if (!data) return;
		const category = data.find(
			(cat) => cat.categoryId === selectedCategoryForEdit.categoryId
		);
		if (category) setSelectedCategoryForEdit(category);
	}

	async function requireCategoryList(page = 1, itemsPerPage = 10) {
		try {
			const data = await categoryProvider.getAll({
				page,
				itemsPerPage,
			});
			if (!data) return;

			setResponse(data);
			getPreviousSelectedCategoryForEdit(data.data);
			setList(mapCategoryDtoListToItems({
				dto: data.data,
				deleteFn: handleDeleteCategoryClick,
				viewFn: handleEditCategoryClick,
			}));
		} catch (error) {
			toast(
				<span>
					<b>Sessão expirada.</b> Você será redirecionado!
				</span>,
				{
					type: "error",
					autoClose: 500,
					onClose: () => {
						navigate("/entrar");
					},
				}
			);
		}
	}

	useEffect(() => {
		requireCategoryList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleEditCategoryClick(category: CategoryDto) {
		setSelectedCategoryForEdit(category);
		setEditCategory(true);
	}

	function handleDeleteCategoryClick(category: CategoryDto) {
		setSelectedCategoryForDelete(category);
		setDeleteCategory(true);
		setEditCategory(false);
	}

	function handleSortList() {
		if (sortKey) {
			const direction = sortDirection === "asc" ? "desc" : "asc";
			const sorted = sortList(
				response.data,
				sortKey as keyof CategoryDto,
				direction
			);
			setResponse({ ...response, data: sorted });
		}
	}

	React.useEffect(() => {
		handleSortList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortDirection, sortKey]);

	return (
		<CategoryListContainer>
			<AddCategory
				open={openAddCategory}
				setOpen={setOpenAddCategory}
				requireCategoryList={requireCategoryList}
			/>
			<EditCategory
				open={editCategory}
				setOpen={setEditCategory}
				requireCategoryList={requireCategoryList}
				category={selectedCategoryForEdit}
				handleDeleteCategoryClick={handleDeleteCategoryClick}
			/>
			<DeleteCategory
				open={deleteCategory}
				setOpen={setDeleteCategory}
				category={selectedCategoryForDelete}
				onFinished={requireCategoryList}
			/>

			<CategoryListHeader></CategoryListHeader>

			<Table<CategoryItem>
					columns={columns}
					dataSource={list || []}
					locale={customLocale}
					pagination={{defaultPageSize: 10}}
					scroll={{ x: 920 }}
					/>
		</CategoryListContainer>
	);
}

export default CategoryListItem;
