import { CommentDto } from '../dto/CommentDto';
import { IPageData } from '../dto/PageData';
import Envoy from '../util/Envoy';

class CommentProvider extends Envoy {
  public async findByIdeiaId(ideaId: string): Promise<IPageData<Array<CommentDto>>> {
    try {
      const response = await this.conn.get(`/idea/comments/idea/${ideaId}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter todas as fases:', error);
      throw error;
    }
  }

  public async postComment(ideaId: string, text: string, repliedCommentId?: string): Promise<CommentDto> {
    try {
      const parentIdQuery = repliedCommentId ? `?parentId=${repliedCommentId}` : '';
      const response = await this.conn.post(`/idea/comments/idea/${ideaId}${parentIdQuery}`, { comment: text });

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao criar comentário:', error);
      throw error;
    }
  }

  public async deleteComment(commentId: number): Promise<void> {
    try {
      const response = await this.conn.delete(`/idea/comments/${commentId}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

    } catch (error) {
      console.error('Erro ao apagar comentário:', error);
      throw error;
    }
  }

  public async deleteCommentById(commentId: number): Promise<void> {
    try {
      await this.deleteComment(commentId);
    } catch (error) {
      console.error('Erro ao apagar comentário:', error);
      throw error;
    }
  }
}

export default CommentProvider;