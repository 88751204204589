import React from "react";
import * as css from "./styles";
import LoginBox from "../components/LoginBox";
import Logo from "../../../shared/util/Logo";

function LoginPage() {
  const Logotipo = Logo("200px", "100px");

  return (
    <css.LoginContainer>
      <css.HeaderContainer>
        <h2>{Logotipo}</h2>
        <p>O IdeiaX é uma plataforma aberta de gestão da inovação para estimular a participação das pessoas da organização no processo de criação de novas soluções. </p>
        <br />
        <p>É o canal para divulgar e compartilhar ideias, permitindo que seus pares possam contribuir, discutir e colaborar para o crescimento e até implementação dela.</p>
        <br />
        <p>Da mesma forma, você pode ver as outras ideias postadas, interagindo com sua opinião e perguntas para melhorá-las ainda mais!</p>
        <br />
        <p>
          Quer implementar ou melhorar um processo de gestão da inovação em sua organização?
        </p>
        <br />
        <a href="#">Clique aqui para mais informações.</a>
      </css.HeaderContainer>
      <css.LoginBoxWrapper>
        <LoginBox />
      </css.LoginBoxWrapper>
    </css.LoginContainer>
  );
}

export default LoginPage;