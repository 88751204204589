import Envoy from '../util/Envoy';
import { IPageData } from '../dto/PageData';
import { IUsuarioSimplificado } from '../dto/UsuarioSimplificado';
import { UpdateUserDto } from '../dto/UpdateUserDto';
import { toast } from 'react-toastify';
import { FileObject } from '../util/Upload';
import { NotificationDto } from '../dto/NotificationDto';

interface IPaginationDTO {
  page?: number
  itemsPerPage?: number
  orderBy?: string
  direction?: 'asc' | 'desc'
}

class UserProvider extends Envoy {

  public async search({
    query,
    page,
    itemsPerPage
  }: {
      query: string,
      page?: number,
      itemsPerPage?: number
    }): Promise<IPageData<Array<IUsuarioSimplificado>>> {
    try {
      const encodedQuery = encodeURIComponent(query);
      const paginationString = this.buildQueryString({page, itemsPerPage});
      const queryString = paginationString.length > 0 ? `${paginationString}&q=${encodedQuery}` : `?q=${encodedQuery}`;
      
      const response = await this.conn.get(`/profile/search${queryString}`);
      
      if (response.data.messages && response.data.messages.length)
         throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error: any) {
      console.error('Erro ao buscar dados:', error);
      if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length) {
        toast.warn(error.response.data.messages.join('\n'));
      }
      throw error;
    }
  }

  public async getAllUsers({
      page,
      itemsPerPage,
      orderBy,
      direction
    } : IPaginationDTO
  ): Promise<IPageData<Array<IUsuarioSimplificado>>> {
    try {
      const paginationString = this.buildQueryString({page, itemsPerPage, orderBy, direction});
      
      const response = await this.conn.get(`/profile${paginationString}`);

      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao buscar todos os usuários:', error);
      throw error;
    }
  }

  public async getById(id: string): Promise<IUsuarioSimplificado> {
    try {
      const response = await this.conn.get(`/profile/${id}`);
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error(`Erro ao buscar usuário com o ID ${id}:`, error);
      throw error;
    }
  }

  public async update(id: string, data: Partial<UpdateUserDto>): Promise<IUsuarioSimplificado> {
    try {
      const response = await this.conn.put(`/profile/${id}`, data);
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error: any) {
      if (error && error.response && error.response.status === 403) {
        toast.warn('Não foi possível atualizar o usuário nesse momento');
      }
      console.error(`Erro ao atualizar usuário com o ID ${id}:`, error);
      throw error;
    }
  }

  public async delete(id: string): Promise<void> {
    try {
      await this.conn.delete(`/profile/${id}`);
      // Optionally, you can handle success cases here
    } catch (error) {
      console.error(`Erro ao deletar usuário com o ID ${id}:`, error);
      throw error;
    }
  }

  public async uploadProfileImage(userId: string, file: FileObject): Promise<void> {
    try {
      //const fileData = new File(file.data, file.name, { type: file.type });
      if (!file.data) throw new Error('Arquivo inválido');
      
      const formData = new FormData();
      formData.append('file', file.data);

      await this.conn.post(`/profile/${userId}/profile-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error(`Erro ao fazer upload da imagem de perfil:`, error);
      throw error;
    }
  }

  public async getOwnProfile(): Promise<IUsuarioSimplificado> {
    try {
      const response = await this.conn.get('/profile/own');
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao buscar o próprio perfil:', error);
      throw error;
    }
  }

  public async getLastsNotifications(): Promise<Array<NotificationDto>> {
    try {
      const response = await this.conn.get('/user/notification');
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao buscar notificações:', error);
      throw error;
    }
  }

  public async markAsRead(notificationId: string): Promise<void> {
    try {
      const response = await this.conn.get(`/user/notification/${notificationId}`);
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
    } catch (error) {
      console.error('Erro ao marcar notificação como lida:', error);
      throw error;
    }
  }

}

export default UserProvider;