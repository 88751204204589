import { FaUser } from "react-icons/fa";
import { ROLES } from "../../../../../shared/guard/Roles";

const MenuItems = [
	
	{
		title: "Permissões",
	},
	{
		title: "Desafios",
		disabled: true,
	},
	{
		title: "Categorias",
	},
	{
		title: "Dimensão",
	},
	{
		title: "Fases",
	},
	{
		title: "Relatório",
		disabled: true,
	},
	{
		title: "Gestão de usuários",
		path: "/adm/gestao-usuario",
		protected: [ROLES.ADMIN]
	},
];

export default MenuItems;
