import { Button, Flex, Tag } from "antd";
import { CardIdeas, CardTitle, CardImage, CardContent } from "./styles";
import { MessageFilled, StarOutlined, StarFilled } from "@ant-design/icons";
import { IdeiaDto } from "../../../../shared/dto/IdeiaDto";
import InteractionsProvider from "../../../../shared/provider/InteractionProvider";
import React from "react";

export interface CardIdeasProps {
    ideia: IdeiaDto;
    handleClick?: (id: string) => void;
}

export const CardIdea: React.FC<CardIdeasProps> = ({ ideia, handleClick }) => {
    const [isFav, setIsFav] = React.useState<boolean>(false);
    
    const interactionProvider = new InteractionsProvider();

    async function handleFavorite(ideaId: string) {
        try {
            const response = await interactionProvider.favorite(ideaId);
            setIsFav(response);
        } catch (error) {
            console.error("Erro ao favoritar a ideia:", error);
        }
    }

    async function checkIsFav(ideaId: string) {
        try {
            const response = await interactionProvider.isFav(ideaId);
            setIsFav(response);
        } catch (error) {
            console.error("Erro ao verificar favorito:", error);
        }
    }

    async function onInit() {
        await checkIsFav(ideia.ideaId as string);
    }

    React.useEffect(() => {
        onInit();
    }, []);

    if (!handleClick || !ideia || !ideia.ideaId) {
        return null; // Retorna null em vez de undefined
    } 

    return (
        <CardIdeas >
            <CardImage onClick={ () => handleClick(ideia.ideaId as string) } src={`${process.env.REACT_APP_API_PUBLIC_URL}/${ideia.category?.coverImage}`} alt={"Cover"} />
            <CardContent>
                <CardTitle onClick={ () => handleClick(ideia.ideaId as string) }>{ideia.title}</CardTitle>
                <section onClick={ () => handleClick(ideia.ideaId as string) }>
                    {ideia.shortDescription}
                </section>
                <footer>
                    <Flex className="footer-idea-showcase" style={{justifyContent: "space-between"}}>
                        <span style={{fontSize: 12, textTransform: "capitalize"}}>
                            <Tag style={{padding: "0 12px", backgroundColor: "#273373", color: "#fff", borderRadius: "12px"}} >
                                {ideia.category?.name}</Tag></span>
                        <Flex gap={3} style={{ alignItems: "center" }}>
                            <span style={{
                                color: "#273373",
                                fontSize: 12,
                            }}>{ideia.commentCount} <MessageFilled /></span>
                            <Button
                             type="link"
                             size={"small"}
                             onClick={() => handleFavorite(ideia.ideaId as string)}
                             icon={
                                isFav ? <StarFilled style={{color: "#273373"}} /> : <StarOutlined style={{color: "#273373"}} />
                            }
                            style={{
                                color: "#273373",
                            }}></Button>
                        </Flex>

                    </Flex>
                </footer>
            </CardContent>
        </CardIdeas>
    );
};