import styled from "styled-components";

export const HeaderContainer = styled.header`
	position: relative;
	z-index: 800;
	margin-bottom: 15px;

	.notification-item {
		background-color: white;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	}
`;

export const HeaderHead = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px 10%;

	.header-logo-text {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		margin-top: 1rem;
	}

	@media (max-width: 500px) {
		.logo-container {
			align-items: center;
			margin-bottom: 12px;
			.hamburger-menu {
				display: block;
				cursor: pointer;
				font-size: 1.5em;
			}
		}
	}

	.hamburger-menu {
		display: none;
	}

	div {
		display: flex;
	}

	.header-head-left {
		div:nth-child(2) {
			height: 35px;
			border-left: 0.5px solid ${({ theme }) => theme.colors.darkGray};
			margin: 0 10px;
			padding-left: 10px;
			align-items: flex-start;
		}
	}

	.header-head-right {
		.header-accessibility {
			display: flex;
			list-style: none;
			padding: 0;
			margin: 0;
			color: ${({ theme }) => theme.colors.primary};
			gap: 10px;
			margin-right: 25px;
			font-size: 1em;

			li {
				transition: all 0.2s ease-in-out;
				cursor: pointer;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				&:hover {
					background: ${({ theme }) => theme.colors.lightGray};
				}
			}

			svg {
				background: none;
				path {
					color: ${({ theme }) => theme.colors.primary};
				}
			}
		}

		span {
			text-decoration: none;
			color: ${({ theme }) => theme.colors.primary};
			font-size: 1em;
			font-weight: 500;
			margin-right: 10px;
			margin-top: 3px;
			cursor: pointer;
		}
	}

	.header-gov-logo {
		background: url("/assets/images/logo_ideiax_nova.png") no-repeat;
		background-size: cover;
		background-position: center;
		width: 120px;
		height: 52px;
		border: 0;
		cursor: pointer;

		@media (max-width: 500px) {
			width: 150px;
			height: 64px;
		}
	}

	@media (max-width: 500px) {
		justify-content: center;
	}
`;

export const HeaderContent = styled.div`
	display: flex;
	top: 0;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
	padding: 12px 4%;
	justify-content: space-between;

	section {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.header-title {
		margin-left: 15px;
		h2 {
			font-weight: 400;
		}
	}

	.header-side-menu-button {
		background: none;
		cursor: pointer;
		margin: 10px;
		transition: all 0.2s ease-in-out;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		svg {
			background: none;
			font-size: 1.4em;
			path {
				color: ${({ theme }) => theme.colors.primary};
			}
		}

		&:hover {
			background: ${({ theme }) => theme.colors.lightGray};
		}
	}
`;

export const HeaderMenuContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 10%;
	margin-top: 2px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

	@media (max-width: 950px) {
		overflow-x: auto;
		overflow-y: hidden;
	}

	@media (max-width: 500px) {
		display: none;
	}

	section {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 15px;
		max-width: 300px;
		width: 100%;
		span {
			font-weight: 600;
		}
	}
`;

interface SideMenuBackdropProps {
	open: boolean;
}

export const SideMenuBackdrop = styled.div<SideMenuBackdropProps>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
	display: ${({ open }) => (open ? "block" : "none")};
`;

interface SideMenuContainerProps {
	open: boolean;
}

export const SideMenuContainer = styled.div<SideMenuContainerProps>`
	position: absolute;
	top: calc(100% + 8px);
	left: 0;
	width: 250px;
	background: #fff;
	z-index: 1000;
	transform: ${({ open }) => (open ? "scaleY(1)" : "scaleY(0)")};
	transform-origin: top;
	transition: transform 0.3s ease-in-out;
	display: flex;
	flex-direction: column;
	padding: 2rem;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		gap: 2rem 0;

		li {
			cursor: pointer;
			color: #1351b4;
			font-size: 1.2em;
			&:hover {
				text-decoration: underline;
			}
		}

		.side-menu-close-button {
			align-self: flex-end;
			cursor: pointer;
			font-size: 1.5em;
			color: gray;
		}
	}
`;

export const DropdownMenu = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background: blue;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	padding: 10px;
`;

export const DropdownMenuItem = styled.div`
	padding: 10px;
	color: white;
	cursor: pointer;
	&:hover {
		background: darkblue;
	}
`;
