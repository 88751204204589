import React, { useState, useEffect } from "react";
import { IdeiaDto } from "../../../../../../shared/dto/IdeiaDto";
import IdeaProvider from "../../../../../../shared/provider/IdeaProvider";
import { RelatedContainer } from "./styles";
import SearchedList from "./components/searchedList";
import { IdeiaDtoRelatedItem } from "./dto/IdeiaDtoRelatedItem";
import { Button, Divider, message, Tooltip, Input, TablePaginationConfig } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, SearchOutlined, ClearOutlined } from "@ant-design/icons";
import PermissionValidator from "../../../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../../../shared/guard/Permissions";

interface RelatedViewProps {
  idea: IdeiaDto;
}

interface MapperProps {
  idea: IdeiaDto;
  ideas: IdeiaDto[];
  type: 'add' | 'remove';
  relatedFn: (relatedIdeaId: string) => void;
}

function mapper(params: MapperProps): IdeiaDtoRelatedItem[] {
  const { ideas, type, relatedFn, idea } = params;
  const permissionValidator = new PermissionValidator();

  let action = <></>;

  return ideas.map(item => {
    if (permissionValidator.hasPermission(PERMISSIONS.RELACIONAR_IDEIAS)) {
      if (type === 'add') {
        action = <Tooltip title="Relacionar ideia">
          <Button type="link" shape="circle" icon={<PlusCircleOutlined />} onClick={() => relatedFn(item.ideaId as string)}></Button>
        </Tooltip>
      }
      if (type === 'remove') {
        action = <Tooltip title="Remover ideia">
          <Button type="link" shape="circle" icon={<MinusCircleOutlined />} onClick={() => relatedFn(item.ideaId as string)}></Button>
        </Tooltip>
      }
    }

    return ({
      ...item,
      authorName: item.author?.name,
      action
    })
  });
}

function RelatedView(params: RelatedViewProps): JSX.Element {
  const [ideas, setIdeas] = useState<IdeiaDto[]>([]);
  const [data, setData] = useState<IdeiaDtoRelatedItem[]>([]);
  const [dataRelated, setDataRelated] = useState<IdeiaDtoRelatedItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 4 });
  const { idea } = params;

  const permissionValidator = new PermissionValidator();

  const ideaProvider = new IdeaProvider();

  async function fetchIdeas(query = "", page = 1, pageSize = 4) {
    try {
      const response = await ideaProvider.searchIdeas({
        page,
        itemsPerPage: pageSize,
        ideaId: idea.ideaId,
        query
      });
      setIdeas(response.data);
      setData(mapper({
        idea,
        ideas: response.data,
        type: 'add',
        relatedFn: addRelated
      }));
      setPagination({
        current: response.currentPage,
        pageSize: response.itemsPerPage,
        total: response.totalItems,
      });
    } catch (error) {
      console.error("Erro ao buscar ideias relacionadas:", error);
    }
  }

  async function fetchRelatedIdeas() {
    try {
      if (!idea.ideaId) return;

      const response = await ideaProvider.getAllRelated(idea.ideaId as string);
      setDataRelated(mapper({
        idea,
        ideas: response,
        type: 'remove',
        relatedFn: removeRelated
      }));
    } catch (error) {
      console.error("Erro ao buscar ideias relacionadas:", error);
    }
  }

  async function addRelated(relatedIdeaId: string) {
    try {
      if (!idea.ideaId) return;
      await ideaProvider.addRelatedIdea(idea.ideaId as string, relatedIdeaId);
      message.success("Ideia relacionada adicionada com sucesso!");
      await fetchRelatedIdeas();
      await fetchIdeas();
    } catch (error) {
      console.error("Erro ao adicionar ideia relacionada:", error);
    }
  }

  async function removeRelated(relatedIdeaId: string) {
    try {
      if (!idea.ideaId) return;
      await ideaProvider.removeRelatedIdea(idea.ideaId as string, relatedIdeaId);
      message.success("Ideia relacionada removida com sucesso!");
      await fetchRelatedIdeas();
      await fetchIdeas();
    } catch (error) {
      console.error("Erro ao remover ideia relacionada:", error);
    }
  }

  async function handleTableChange(pagination: TablePaginationConfig, filters: any, sorter: any) {
    await fetchIdeas(searchTerm, pagination.current, pagination.pageSize);
  }

  async function handleSearch() {
    await fetchIdeas(searchTerm);
  }

  async function handleClearSearch() {
    setSearchTerm("");
    await fetchIdeas("");
  }

  useEffect(() => {
    fetchRelatedIdeas();
  }, []);

  return (
    <RelatedContainer>
      {
        permissionValidator.hasPermission(PERMISSIONS.RELACIONAR_IDEIAS) &&
        <>
          <div className="search-container">
            <Input
              placeholder="Digite sua pesquisa"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onPressEnter={handleSearch}
              allowClear
            />
            <Tooltip title="Pesquisar">
              <Button shape="circle" icon={<SearchOutlined />} onClick={handleSearch} />
            </Tooltip>
            <Tooltip title="Limpar Pesquisa">
              <Button icon={<ClearOutlined />} onClick={handleClearSearch}>
                Limpar
              </Button>
            </Tooltip>
          </div>
        {ideas && !!ideas.length && <SearchedList data={data} handleTableChange={handleTableChange} pagination={pagination} />}
        <Divider />
      </>
      }
      <h3>Ideias relacionadas</h3>
      <SearchedList data={dataRelated} handleTableChange={handleTableChange} pagination={false} />
    </RelatedContainer>
  );
}

export default RelatedView;