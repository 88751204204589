import React, { ReactNode } from "react";
import { MinhaIdeiaContext } from "./context";
import { IdeiaDto } from "../../dto/IdeiaDto";
import IdeaProvider from "../../provider/IdeaProvider";
import { ConfigProvider, message } from "antd";
import { MetaSimplificadoDto } from "../../dto/MetaSimplificadoDto";

interface IdeiaProviderProps {
  children: ReactNode;
}

function IdeiaContextProvider({ children }: IdeiaProviderProps) {
  const [ideias, setIdeias] = React.useState<IdeiaDto[]>([]);
  const [ideia, setIdeia] = React.useState<IdeiaDto>({} as IdeiaDto);
  const [page, setPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [openModal, setOpenModal] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [images, setImages] = React.useState<MetaSimplificadoDto[]>([]);

  const ideaProvider = new IdeaProvider();

  async function loadIdeias({ page, itemsPerPage }: { page?: number; itemsPerPage?: number }) {
    try {
      const response = await ideaProvider.getMyIdeas({
        page: page ?? 1,
        itemsPerPage: itemsPerPage ?? 10,
      });
      setIdeias(response.data);
      setPage(response.currentPage);
      setItemsPerPage(response.itemsPerPage);
      setTotalItems(response.totalItems);
      if (ideia.ideaId) {
        recoverSelectedIdeia(ideia.ideaId, response.data);
        getIdeaImages(ideia.ideaId);
      }
      setLoading(false);
    } catch (error) {
      console.error("Erro ao obter minhas idéias:", error);
      messageApi.error("Erro ao obter minhas idéias");
    }
    finally {
      setLoading(false);
    }
  }

  async function getIdeaImages(id: string) {
    try {
      if (!id) return;
      const response = await ideaProvider.getImages(id);
      setImages(response);
    } catch (error) {
      console.error('Erro ao obter imagens:', error);
    }
  }

  function recoverSelectedIdeia(ideiaId: string, list: IdeiaDto[]) {
    const ideiaRec = list.find((ide) => ide.ideaId === ideiaId);
    if (ideiaRec) {
      setIdeia(ideiaRec);
    }
  }
  
  React.useEffect(() => {
    if (ideia && ideia.ideaId) {
      getIdeaImages(ideia.ideaId);
    }
  }
  ,[ideia]);

  return (
    <MinhaIdeiaContext.Provider value={{
      ideias,
      loading,
      ideia,
      setIdeia,
      setIdeias,
      loadIdeias,
      openModal,
      setOpenModal,
      page,
      itemsPerPage,
      totalItems,
      setItemsPerPage,
      setPage,
      openView,
      setOpenView,
      openEdit,
      setOpenEdit,
      images,
      setImages,
      getIdeaImages,
      messageApi,
      }}>
      <ConfigProvider theme={{components: {Message: { zIndexPopup: 99999999 }}}}>
        {contextHolder}
      </ConfigProvider>
      {children}
    </MinhaIdeiaContext.Provider>
  );
}

function useIdeia() {
  const context = React.useContext(MinhaIdeiaContext);
  return context;
}

export default IdeiaContextProvider;
export { useIdeia };