import React from "react";
import { LoginSlideContainer, Overlay } from "./styles";
import SlideShow from "../../../../shared/util/SlideShow";
import slideList from "./config/slideList";

function LoginSlide(): JSX.Element {
  return (
    <LoginSlideContainer>
      <Overlay />
      <SlideShow slides={slideList} />
    </LoginSlideContainer>
  );
}

export default LoginSlide;
