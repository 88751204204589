import styled from "styled-components";

export const IdeaListContainer = styled.div`
  height: 100%;

  .infinite-scroll-initial-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin: 0 3%;
    padding: 1%;
  }
`;