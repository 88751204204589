import styled from "styled-components";

export const FooterContainer = styled.footer`
  background: linear-gradient(to right, #328bae, #273373);
  height: 81px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10%;

  @media (max-width: 620px) {
    flex-wrap: wrap;
    height: auto;
  }

  * {
    background: none;
    font-family: "Plus Jakarta Sans", sans-serif;
  }

  a {
    list-style: none;
  }

  ul, ol, li {
    list-style: none;
  }

  li {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      padding: 5px 10px;
      font-size: 0.966rem;
      margin: 0 10px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

    } 

  head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;

    

    button {
      margin-right: 20px;
      background: transparent;
      border: none;
      cursor: pointer;
    }

    .logo-footer {
      background: transparent;
      display: flex;
      align-items: center;
     
      @media (max-width: 620px) {
        justify-content: center;
        flex-wrap: wrap;
        height: auto;
      }

      a {
        margin-right: 20px;
      }

      .social-nav {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
          margin-bottom: 10px;
          font-size: 14px;
          color: ${({ theme }) => theme.colors.background};
        }

        ul {
          width: 100%;
          list-style: none;
          display: flex;
          justify-content: center;
          margin-left: 20px;
        }
      }
    }
  }

  section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    background: none;

    nav ul li {
        color: ${({ theme }) => theme.colors.background};
      }

    span {
      font-size: 0.1rem;
      color: ${({ theme }) => theme.colors.background};

    }

    a {
      background: transparent;
    }

    span {
      font-size: 0.833rem;
    }
  }
`;

export const FooterAcessoInformacaoImage = styled.div<{ image: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  background: url(${props => props.image}) no-repeat center center;
  background-size: contain;
  background-color: transparent;
  height: 55px;
`;