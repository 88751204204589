import { Avatar } from 'antd';
import { CommentDto } from '../../../../../../../shared/dto/CommentDto';

interface CustomCommentProps {
  comment: CommentDto
}

const CustomComment = ({ comment }: CustomCommentProps) => (
  <div>
    <Avatar src={""} style={{ marginRight: 8 }} />
    <div>
      <div style={{ fontWeight: 'bold' }}>{comment.author?.name}</div>
      <div>{comment.content}</div>
    </div>
  </div>
);

export default CustomComment;
