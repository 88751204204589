import styled from "styled-components";

export const CardIdeas = styled.div`
  width: 350px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;

  @media (max-width: 933px) {
    width: 100%;
    height: auto;
    margin: 0;
  }

  &:hover {
    transform: scale(1.05);
    @media (max-width: 933px) {
      transform: unset;
    }
  }
`;

export const CardTitle = styled.div`
  background: #fff;
  cursor: pointer;
  min-height: 44px;
  color: #000;
  font-weight: bold;
  font-size: 1rem;
  margin: 0.6rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const CardContent = styled.div`
  background: rgb(255, 255, 255);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Altura inicial para mostrar o título */
  padding: 0 1.5rem;
  transition: height 0.5s ease;

  @media (max-width: 536px) {
    position: relative;
  }

  @media (max-width: 933px) {
    height: auto;
    position: relative;
  }

  ${CardIdeas}:hover & {
    height: calc(100% - 40px); /* Expande para 95% da altura da imagem */
    @media (max-width: 933px) {
      height: auto;
    }
  }

  footer {
    margin: 8px 0;
  }

  section {
    margin-top: 15px;
    font-size: 13.44px;
    height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const CardImage = styled.img`
  cursor: pointer;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  display: block;
  margin-bottom: 60px;
  @media (max-width: 933px) {
    margin-bottom: 0;
  }
`;