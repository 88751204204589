import React, { useEffect, useState } from "react";
import { RoleListContainer, RoleListHeader } from "./styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IPageData } from "../../../../shared/dto/PageData";
import RoleProvider from "../../../../shared/provider/RoleProvider";
import { RoleOptionValue } from "../../../../shared/dto/OptionValue";
import AddRole from "../AddRole";
import EditRole from "../EditRole";
import DeleteRole from "../DeleteRole";
import sortList from "../../../../shared/util/Order";
import { Table } from "antd";
import { RoleItem } from "../../../../shared/dto/RoleItem";
import columns from "./config/columns";
import customLocale from "../../../../shared/constants/customLocale";
import mapRoleDtoListToItems from "./utils/mapDtoToItem";

function RoleList({
	openAddRole,
	setOpenAddRole,
}: {
	openAddRole: boolean;
	setOpenAddRole: React.Dispatch<boolean>;
}): JSX.Element {
	const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">();
	const [sortKey, setSortKey] = React.useState<string>();
	const [editRole, setEditRole] = React.useState(false);
	const [deleteRole, setDeleteRole] = React.useState(false);
	const [selectedRoleForEdit, setSelectedRoleForEdit] = React.useState(
		{} as RoleOptionValue
	);
	const [selectedRoleForDelete, setSelectedRoleForDelete] = React.useState(
		{} as RoleOptionValue
	);

	const [response, setResponse] = useState<IPageData<Array<RoleOptionValue>>>(
		{} as IPageData<Array<RoleOptionValue>>
	);
	const [list, setList] = useState<RoleItem[]>([]);


	const roleProvider = new RoleProvider();
	const navigate = useNavigate();

	function getPreviousSelectedRoleForEdit(data: RoleOptionValue[]) {
		if (!data) return;
		const role = data.find((role) => role.value === selectedRoleForEdit.value);
		if (role) setSelectedRoleForEdit(role);
	}

	async function requireRoleList(
		page = 1,
		itemsPerPage = 10,
		orderBy?: string,
		direction?: "asc" | "desc"
	) {
		try {
			const data = await roleProvider.getAllWithPagination({
				page,
				itemsPerPage,
				orderBy,
				direction,
			});
			if (!data) return;

			setResponse(data);
			setList(mapRoleDtoListToItems({
				dto: data.data,
				deleteFn: handleDeleteRoleClick,
				viewFn: handleEditRoleClick,
			}));
			getPreviousSelectedRoleForEdit(data.data);
		} catch (error) {
			toast(
				<span>
					<b>Sessão expirada.</b> Você será redirecionado!
				</span>,
				{
					type: "error",
					autoClose: 500,
					onClose: () => {
						navigate("/entrar");
					},
				}
			);
		}
	}

	useEffect(() => {
		requireRoleList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleEditRoleClick(role: RoleOptionValue) {
		setSelectedRoleForEdit(role);
		setEditRole(true);
	}

	function handleDeleteRoleClick(role: RoleOptionValue) {
		setSelectedRoleForDelete(role);
		setDeleteRole(true);
	}

	function handleSortList() {
		if (sortKey) {
			const direction = sortDirection === "asc" ? "desc" : "asc";
			const sorted = sortList(
				response.data,
				sortKey as keyof RoleOptionValue,
				direction
			);
			setResponse({ ...response, data: sorted });
		}
	}

	React.useEffect(() => {
		handleSortList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortDirection, sortKey]);

	return (
		<RoleListContainer>
			<AddRole
				open={openAddRole}
				setOpen={setOpenAddRole}
				requireRoleList={requireRoleList}
			/>
			<EditRole
				open={editRole}
				setOpen={setEditRole}
				requireRoleList={requireRoleList}
				role={selectedRoleForEdit}
			/>
			<DeleteRole
				open={deleteRole}
				setOpen={setDeleteRole}
				role={selectedRoleForDelete}
				onFinished={requireRoleList}
			/>
			<RoleListHeader></RoleListHeader>

			<Table<RoleItem>
					columns={columns}
					dataSource={list || []}
					locale={customLocale}
					pagination={{defaultPageSize: 10}}
					scroll={{ x: 920 }}
					/>
		</RoleListContainer>
	);
}

export default RoleList;
