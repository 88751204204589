import { Badge, Button, Flex } from "antd";
import generateUUID from "../../../../shared/constants/uuid";
import { StageDto } from "../../../../shared/dto/StageDto";
import { StageListContainer } from "./styles";
import React from "react";
import { IIdeiasDTO } from "../../../../shared/provider/IdeaProvider";
import { CloseOutlined } from "@ant-design/icons";

interface StageListProps {
  stages: StageDto[];
}

function StageList({ stages }: StageListProps): JSX.Element {
  const [orderedStages, setOrderedStages] = React.useState<StageDto[]>([]);
  const [selectedStage, setSelectedStage] = React.useState<string>("");

  React.useEffect(() => {
    const stagesCopy = [...stages];
    const orderedStages = stagesCopy.sort((a, b) => a.order - b.order);
    setOrderedStages(orderedStages);
  }, [stages]);

  return (
    <StageListContainer>
      
      {orderedStages.map((stage) => (
        <li key={generateUUID()}>
          <Flex gap={5} style={{ alignItems: "center" }}>
            <div>
              <Button
                type="link"
                style={{
                  border: selectedStage === stage.stageId ? "1px solid #1351b4" : "none",
                  cursor: "unset"
                }}
              >
                <span style={{ color: "#303030" }}>
                  {stage.title}
                </span>
                {!stage.totalIdeas ? "" : (
                  <Badge
                    count={stage.totalIdeas}
                    showZero
                    color="#1351b4"
                    style={{
                      padding: "0 14px",
                      borderRadius: "6px",
                    }}
                  />
                )}
              </Button>
            </div>
          </Flex>
        </li>
      ))}
    </StageListContainer>
  );
}

export default StageList;