import styled from "styled-components";

export const LoginSlideContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
  height: 489px;
  width: 100%;
	position: relative;
  background: transparent;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px; 
	z-index: 1000;
`;