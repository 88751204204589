import React from "react";
import {
  LoginBoxContainer,
  LoginBoxContent,
  LoginBoxFooter,
} from "./styles";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../../../../shared/provider/AuthProvider";

interface LoginBoxProps {
  className?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserNotFound: React.Dispatch<React.SetStateAction<boolean>>;
}

function LoginBox({ className, open, setOpen, setUserNotFound }: LoginBoxProps): JSX.Element {
  const navigate = useNavigate();
  const authProvider = new AuthProvider();

  React.useEffect(() => {
    if (!open) setUserNotFound(false);
  }, [open]);

  React.useEffect(() => {
    tryRefreshToken();
  }, []);

  React.useEffect(() => {
    if (open) {
      window.localStorage.removeItem("tenantName");
    }
  }, [open]);

  async function tryRefreshToken() {
    try {
      const response = await authProvider.refreshSession();
      if (!response) return false;
      navigate("/");
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <LoginBoxContainer className={className}>
      <LoginBoxContent>
        <h3>
          Já faz parte da nossa comunidade?
        </h3>

        <p><b>Entre e cadastre uma ideia!</b></p>

        <DefaultButton
        width="160px"
        marginTop="12px"
          params={{
            onClick: () => setOpen(true),
          }}
        >
          Login
        </DefaultButton>
      </LoginBoxContent>

      <LoginBoxFooter>
      </LoginBoxFooter>
    </LoginBoxContainer>
  );
}

export default LoginBox;
