import React from "react";
import { AddModalContainer, AddModalForm } from "./styles";
import { useIdeia } from "../../../../shared/context/ideia/provider";
import {
    ConfigProvider,
    Flex,
    GetProp,
    Input,
    message,
    Modal,
    Select,
    Upload,
    UploadFile,
    UploadProps,
} from "antd";
import CategoryProvider from "../../../../shared/provider/CategoryProvider";
import { CategoryDto } from "../../../../shared/dto/CategoryDto";
import PermissionValidator from "../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../shared/guard/Permissions";
import UserProvider from "../../../../shared/provider/UserProvider";
import DebounceSelect from "./utils/DebouncedSelect";
import ImgCrop from "antd-img-crop";
import IdeaProvider, { CreateIdeiaDto } from "../../../../shared/provider/IdeaProvider";
import CustomEditor from "./components/CustomEditor";
import ChallengeDto from "../../../../shared/dto/ChallengeDto";
import { addModalValidation } from "./validation";
import DefaultButton from "../../../../shared/util/DefaultButton";
import ptBR from "antd/lib/locale/pt_BR";
import TextArea from "antd/es/input/TextArea";

export interface UserValue {
    label: string;
    value: string;
}

function AddModal(): JSX.Element {
    const permissionValidator = new PermissionValidator();
    const { openModal, setOpenModal, messageApi } = useIdeia();

    type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

    const [shortDescriptionCount, setShortDescriptionCount] = React.useState(0);

    const [targetAudienceCount, setTargetAudienceCount] = React.useState(0);

    React.useEffect(() => {
        if (!permissionValidator.hasPermission(PERMISSIONS.INSERIR_IDEIA)) {
            setOpenModal(false);
        }
    }, []);

    const [solution, setSolution] = React.useState("");
    const [categoryValue, setCategoryValue] = React.useState("");
    const [challengeValue, setChallengeValue] = React.useState("");
    const [categoryData, setCategoryData] = React.useState<CategoryDto[]>([]);
    const [challengeData, setChallengeData] = React.useState<ChallengeDto[]>([]);
    const [usersValue, setUsersValue] = React.useState<UserValue[]>([]);
    const [titleValue, setTitleValue] = React.useState("");
    const [shortDescriptionValue, setShortDescriptionValue] = React.useState("");
    const [reportedProblemValue, setReportedProblemValue] = React.useState("");
    const [targetAudienceValue, setTargetAudienceValue] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [successModalVisible, setSuccessModalVisible] = React.useState(false);
    const [warningModalVisible, setWarningModalVisible] = React.useState(false);

    const categoryProvider = new CategoryProvider();
    const userProvider = new UserProvider();
    const ideaProvider = new IdeaProvider();

    function onChangeSolution(e: any) {
        setSolution(e.target.value);
    }

    const onChangeFileUpload: UploadProps["onChange"] = ({ fileList: newFileList }) => {
        if (newFileList.length > fileList.length) return;
        setFileList(newFileList);
    };

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as FileType);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    async function handleSearch(searchString: string) {
        try {
            const data = searchString
                ? await userProvider.search({
                    page: 1,
                    itemsPerPage: 10,
                    query: searchString,
                })
                : await userProvider.getAllUsers({ page: 1, itemsPerPage: 10 });
            if (!data || !data.data.length) throw new Error("Nenhum usuário encontrado");

            return data.data.map((d) => ({
                value: d.id,
                label: d.name,
            }));
        } catch (error: any) {
            return [];
        }
    }

    async function getAllCategory() {
        try {
            const response = await categoryProvider.getAll({ page: 1, itemsPerPage: 500 });
            if (response) {
                setCategoryData(response.data);
            }
        } catch (error) {}
    }

    function handleCategoryChange(value: string) {
        setCategoryValue(value);
    }

    function handleChallengeChange(value: string) {
        setChallengeValue(value);
    }

    const beforeUpload = (file: File) => {
        const isPdf = file.type === "application/pdf";
        const isImage = file.type.startsWith("image/");
        if (!isPdf && !isImage) {
            message.error("Você só pode fazer upload de arquivos PDF ou imagens!");
            return Upload.LIST_IGNORE;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                if (ctx) {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                const newFile: UploadFile<any> = {
                                    uid: new Date().getTime().toString(),
                                    name: file.name,
                                    status: "done",
                                    url: URL.createObjectURL(blob),
                                };
                                setFileList((prevFileList) => [...prevFileList, newFile]);
                            }
                        },
                        file.type
                    );
                }
            };
        };
        return false;
    };

    function onInit() {
        getAllCategory();
        setCategoryValue("");
        setSolution("");
        setUsersValue([]);
    }

    function clearForm() {
        setCategoryValue("");
        setChallengeValue("");
        setSolution("");
        setUsersValue([]);
        setFileList([]);
        setTitleValue("");
        setShortDescriptionValue("");
        setReportedProblemValue("");
        setTargetAudienceValue("");
    }

    async function create() {
        setLoading(true);
        const categorySelected = categoryData.find(
            (category) => category.categoryId === categoryValue
        );
        const challengeSelected = challengeData.find(
            (challenge) => challenge.challengeId === challengeValue
        );

        const data: CreateIdeiaDto = {
            idea: {
                title: titleValue,
                shortDescription: shortDescriptionValue,
                reportedProblem: reportedProblemValue,
                solution: solution,
                targetAudience: targetAudienceValue,
                challenge: challengeSelected,
                category: categorySelected,
            },
            coAuthorsId: usersValue.map((user) => user.value),
        };

        try {
            const erros = addModalValidation(data, "insert");

            if (erros.length) {
                erros.forEach((erro) => {
                    messageApi.warning({ content: erro.message, key: erro.field });
                });
                return;
            }

            const ideia = await ideaProvider.createOrUpdate(data);

            if (ideia) {
                if (fileList && fileList.length && ideia.ideaId) {

                    const uploadRequests: Promise<void>[] = [];
                    fileList.forEach((file) => {
                        ideaProvider.uploadImage(ideia.ideaId as string, file);
                    });
                    await Promise.all(uploadRequests);
                }
                setSuccessModalVisible(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        create();
    }

    function handleClose() {
        if (
            titleValue ||
            shortDescriptionValue ||
            reportedProblemValue ||
            solution ||
            targetAudienceValue
        ) {
            setWarningModalVisible(true);
        } else {
            setOpenModal(false);
        }
    }

    function closeAllModals() {
        setSuccessModalVisible(false);
        setWarningModalVisible(false);
        setOpenModal(false);
    }

    React.useEffect(() => {
        if (openModal) {
            onInit();
        } else {
            clearForm();
        }
    }, [openModal]);

    return (
        <AddModalContainer>
            <Modal
                title={<h2>Nova ideia</h2>}
                open={openModal}
                onOk={() => create()}
                onCancel={handleClose}
                okText="Salvar ideia"
                maskClosable={false}
                width={1280}
                footer={[
                    <DefaultButton
                        key={"back"}
                        width="150px"
                        height="35px"
                        fontSize="1em"
                        withOutBackground={true}
                        params={{
                            type: "button",
                            onClick: handleClose,
                            style: { marginRight: "10px" },
                        }}
                    >
                        Fechar
                    </DefaultButton>,
                    <DefaultButton
                        key={"submit"}
                        width="150px"
                        height="35px"
                        fontSize="1em"
                        params={{
                            type: "button",
                            onClick: () => create(),
                        }}
                    >
                        Salvar ideia
                    </DefaultButton>,
                ]}
                centered
            >
                <AddModalForm onSubmit={handleSubmit}>
                    <div>
                        <label className={"input-label"} htmlFor="title">
                            Título*:
                        </label>
                        <TextArea
                            id="title"
                            showCount
                            maxLength={120}
                            value={titleValue}
                            onChange={(e) => setTitleValue(e.target.value)}
                            autoSize={{ minRows: 1, maxRows: 3 }}
                        />
                    </div>

                    <div>
                        <label className={"input-label"} htmlFor="shortDescription">
                            Resumo*:
                        </label>
                        <textarea
                            id="shortDescription"
                            value={shortDescriptionValue}
                            onChange={(e) => {
                                setShortDescriptionValue(e.target.value);
                                setShortDescriptionCount(e.target.value.length);
                            }}
                            maxLength={300}
                            style={{
                                width: "100%",
                                minHeight: "100px",
                                padding: "8px",
                                border: "1px solid #d9d9d9",
                                borderRadius: "4px",
                            }}
                        />
                        <span
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                fontSize: "15px",
                                color: "rgba(0, 0, 0, 0.45)",
                                overflow: "auto",
                            }}
                        >
                            {shortDescriptionCount}/300
                        </span>
                    </div>

                    <div>
                        <label className={"input-label"} htmlFor="reportedProblem">
                            Problema identificado*:
                        </label>
                        <CustomEditor
                            value={reportedProblemValue}
                            onChange={(e) => setReportedProblemValue(e.target.value)}
                            showCharCount
                            charLimit={3000}
                        />
                    </div>

                    <div>
                        <label className={"input-label"} htmlFor="solution">
                            Solução proposta*:
                        </label>
                        <CustomEditor
                            value={solution}
                            onChange={onChangeSolution}
                            showCharCount
                            charLimit={6000}
                            
                        />
                    </div>

                    <ConfigProvider locale={ptBR}>
                    <div>
                            <label className={"input-label"} htmlFor="">
                                Anexos:
                            </label>
                            <p>Formatos aceitos: .jpg, .png, .pdf. Tamanho máximo: 5MB.</p>
                            <ImgCrop aspect={16 / 9} rotationSlider>
                                <Upload
                                    beforeUpload={beforeUpload}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChangeFileUpload}
                                    onPreview={onPreview}
                                >
                                    {fileList.length < 3 && "+ Upload"}
                                </Upload>
                            </ImgCrop>
                        </div>
                    </ConfigProvider>

                    <div>
                        <label className={"input-label"} htmlFor="targetAudience">
                            Público alvo*:
                        </label>
                        <TextArea
                            id="targetAudience"
                            maxLength={200}
                            value={targetAudienceValue}
                            onChange={(e) => setTargetAudienceValue(e.target.value)}
                            showCount
                            autoSize={{ minRows: 1, maxRows: 3 }}
                            />
                    </div>

                    <Flex gap={10} style={{width: "100%"}}>
                        <div style={{width: "100%"}}>
                            <label className={"input-label"} htmlFor="category">
                                Categoria:
                            </label>
                            <Select
                                id="category"
                                value={categoryValue}
                                onChange={handleCategoryChange}
                                style={{width: "100%"}}
                                notFoundContent={<div>Nenhuma categoria encontrada</div>}
                                options={
                                    (categoryData || []).map((d) => ({
                                        value: d.categoryId,
                                        label: d.name,
                                    }))
                                }
                            />
                        </div>

                        {challengeData && !!challengeData.length && (
                            <div style={{width: "100%"}}>
                                <label className={"input-label"} htmlFor="challenge">
                                    Desafio:
                                </label>
                                <Select
                                    id="challenge"
                                    style={{width: "100%"}}
                                    notFoundContent={<div>Nenhum desafio encontrado</div>}
                                    value={challengeValue}
                                    onChange={handleChallengeChange}
                                    options={
                                        (challengeData || []).map((d) => ({
                                            value: d.challengeId,
                                            label: d.title,
                                        }))
                                    }
                                />
                            </div>
                        )}
                    </Flex>

                    <div>
                        <label className={"input-label"} htmlFor="coAuthors">
                            Coautores:
                        </label>
                        <DebounceSelect
                            mode="multiple"
                            value={usersValue}
                            placeholder="Selecione os coautores"
                            fetchOptions={handleSearch}
                            notFoundContent={<div>Nenhum usuário encontrado</div>}
                            onChange={(newValue) => {
                                setUsersValue(newValue as UserValue[]);
                            }}
                            style={{width: "100%"}}
                        />
                    </div>
                </AddModalForm>
            </Modal>

            <Modal
                title={<h3 style={{textAlign: "center", width:"100%"}}>Confirmação</h3>}
                visible={warningModalVisible}
                onCancel={() => setWarningModalVisible(false)}
                footer={
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <DefaultButton
                            key={"cancel"}
                            width="150px"
                            height="35px"
                            fontSize="1em"
                            withOutBackground={true}
                            params={{
                                type: "button",
                                onClick: () => setWarningModalVisible(false),
                            }}
                        >
                            Não
                        </DefaultButton>
                        <DefaultButton
                            key={"confirm"}
                            width="150px"
                            height="35px"
                            fontSize="1em"
                            params={{
                                type: "button",
                                onClick: () => {
                                    setWarningModalVisible(false);
                                    setOpenModal(false);
                                },
                            }}
                        >
                            Sim
                        </DefaultButton>
                    </div>
                }
                centered
            >
                <p style={{ textAlign: "center" }}>
                    Sua ideia ainda não foi salva. Ao fechar o formulário as informações
                    serão apagadas. Deseja fechar a janela?
                </p>
            </Modal>

            <Modal
                title={<h3 style={{textAlign:"center"}}>Ideia criada com sucesso</h3>}
                visible={successModalVisible}
                onCancel={() => setSuccessModalVisible(false)}
                footer={
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <DefaultButton
                            key={"close"}
                            width="150px"
                            height="35px"
                            fontSize="1em"
                            params={{
                                type: "button",
                                onClick: closeAllModals,
                            }}
                        >
                            Fechar
                        </DefaultButton>
                    </div>
                }
                centered
            >
            </Modal>
        </AddModalContainer>
    );
}

export default AddModal;