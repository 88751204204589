import React, { useEffect, useState } from "react";
import { Slide, SlideshowContainer, Title } from "./styles";

interface SlideShowData {
  path: string;
  title: string;
}

interface SlideShowProps {
  slides: SlideShowData[];
}

function SlideShow({ slides }: SlideShowProps): JSX.Element {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide) =>
        currentSlide === slides.length - 1 ? 0 : currentSlide + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <SlideshowContainer>
      <Title>{slides[currentSlide].title}</Title> {/* Renderiza o título fora do slide */}
      {slides.map((slide, index) => (
        <Slide
          key={index}
          backgroundImage={slide.path}
          isActive={index === currentSlide}
        />
      ))}
    </SlideshowContainer>
  );
}

export default SlideShow;