import styled from "styled-components";

export const CategoryListContainer = styled.div`
  margin: 2% 0;
  @media(max-width: 850px) {
    overflow: scroll;
  }
`;

export const CategoryListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 500;
  }
  div {
    display: flex;
  }

`;
export const CategoryListContent = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 850px;

  .listing-header {
    width: 100%;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGray};
    height: 60px;
    padding: 0 20px;
    th {
      background-color: ${({ theme }) => theme.colors.lightGray};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }
  }
  `;


export const CategoryThumbnail = styled.div<{url?: string}>`
  width: 158px;
  height: 67px;
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

`;