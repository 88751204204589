import React, { useEffect } from "react";
import { LayoutContainer, ContentWrapper } from "../styles/layout";
import Header from "../shared/util/Header";
import { Outlet } from "react-router-dom";
import Breadcrumb from "../shared/util/Breadcrumb";
import Footer from "../shared/util/Footer";
import TenantModal from "../shared/util/TenantModal";
import AddModal from "../modules/ideia/components/AddModal";
import ViewModal from "../modules/ideia/components/ViewModal";
import EditModal from "../modules/ideia/components/EditModal";

function Layout(): JSX.Element {
    const [tenantModalOpen, setTenantModalOpen] = React.useState(false);
    useEffect(() => {
        if (
            !window.localStorage.getItem("tenantName") ||
            window.localStorage.getItem("tenantName") === "undefined" ||
            window.localStorage.getItem("tenantName") === "null"
        ) {
            setTenantModalOpen(true);
        }
    }, [window.localStorage.getItem("tenantName")]);
    return (
        <LayoutContainer>
            <AddModal />
            <ViewModal />
            <EditModal />
            <TenantModal
                open={tenantModalOpen}
                setOpen={setTenantModalOpen}
            />
            <Header />
            <ContentWrapper>
                <Outlet />
            </ContentWrapper>
            <Footer />
        </LayoutContainer>
    );
}

export default Layout;