import React from "react";
import {
	EditDivider,
	EditStageFormContainer,
} from "./styles";
import { toast } from "react-toastify";
import StageInput from "../../StageInput";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import { StageDto } from "../../../../../../../shared/dto/StageDto";
import StageProvider from "../../../../../../../shared/provider/StageProvider";
import { Flex } from "antd";
import TextArea from "antd/es/input/TextArea";

function EditStageForm({
	setOpen,
	isLoading = false,
	setLoading,
	setSuccessMesstage,
	requireStageList,
	stage,
	setEditMode,
}: {
	readonly setOpen: React.Dispatch<boolean>;
	readonly isLoading?: boolean;
	readonly setLoading: React.Dispatch<boolean>;
	readonly setSuccessMesstage: React.Dispatch<string>;
	readonly requireStageList: () => Promise<void>;
	readonly stage: StageDto;
	readonly setEditMode: React.Dispatch<boolean>;
}) {
	const [stageName, setStageName] = React.useState<string>(
		stage.title ?? ""
	);
	const [stageDescription, setStageDescription] = React.useState<string>(
		stage.description ?? ""
	);
	const [stageOrder, setStageOrder] = React.useState<string>(
		stage.order.toString() ?? ""
	);

	const stageProvider = new StageProvider();

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		updateStage();
	}

	async function updateStage() {
		if (!stageName || !stageDescription || !stageOrder) {
			toast.error("Preencha todos os campos");
			return;
		}

		setLoading(true);

		try {
			const orderValue = Math.min(Number(stageOrder), 50);

			const response = await stageProvider.createOrUpdate({
				stageId: stage.stageId,
				title: stageName,
				description: stageDescription,
				order: orderValue,
			});
			if (!response) throw new Error("Erro ao criar fase da idéia");
			setSuccessMesstage("Alterações salvas com sucesso!");
			setTimeout(() => requireStageList(), 550);
		} catch (error) {
			toast.error("Erro ao alterar fase da idéia");
		} finally {
			setLoading(false);
		}
	}

	const handleOrderChange = (value: string) => {
		const numericValue = Number(value);
		if (numericValue > 50) {
			setStageOrder("50");
		} else if (numericValue < 0) {
			setStageOrder("0");
		} else {
			setStageOrder(value);
		}
	};

	return (
		<EditStageFormContainer onSubmit={handleSubmit}>
			<Flex gap={6} style={{marginBottom: 25, flexDirection: "column" }}>
					<label className={"input-label"} htmlFor="title">
						Nome da fase*:
					</label>
					<TextArea
							id="nome"
							showCount
							placeholder="Informe o nome da fase"
							maxLength={200}
							value={stageName}
							onChange={(e) => setStageName(e.target.value)}
							autoSize={{ minRows: 1, maxRows: 3 }}
							disabled={isLoading}
					/>
			</Flex>

			<EditDivider />

			<StageInput
				label="Descrição"
				value={stageDescription}
				setValue={setStageDescription}
				placeholder="Descreva a Fase"
				isLoading={isLoading}
				width="100%"
				height="130px"
				limitCharacters={400}
				type="textarea"
			/>

			<StageInput
				label="Ordem"
				value={stageOrder}
				setValue={handleOrderChange}
				placeholder="0"
				isLoading={isLoading}
				width="12%"
				type="number"
			/>

			<footer>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					withOutBackground={true}
					params={{
						onClick: () => setOpen(false),
					}}
				>
					Fechar
				</DefaultButton>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					params={{
						type: "submit",
					}}
				>
					Salvar
				</DefaultButton>
			</footer>
		</EditStageFormContainer>
	);
}

export default EditStageForm;