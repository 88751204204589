import { CreateRatingItemDTO, RatingDto } from '../dto/RatingDto';
import Envoy from '../util/Envoy';

class RatingProvider extends Envoy {
  public async saveDraft(ideaId: string, payload: CreateRatingItemDTO[]): Promise<RatingDto> {
      try {
        const response = await this.conn.post(`/idea/rating/draft/${ideaId}`, payload);
        
        if (response.data.messages?.length)
          throw new Error(response.data.messages.join('\n'));

        return response.data.data;
      } catch (error) {
        console.error('Erro ao obter todas as fases:', error);
        throw error;
      }
    }

    public async saveRating(ideaId: string, payload: CreateRatingItemDTO[]): Promise<RatingDto> {
      try {
        const response = await this.conn.post(`/idea/rating/${ideaId}`, payload);
        
        if (response.data.messages?.length)
          throw new Error(response.data.messages.join('\n'));
  
        return response.data.data;
      } catch (error) {
        console.error('Erro ao salvar a avaliação:', error);
        throw error;
      }
    }
  
    public async getRatingByIdeaId(ideaId: string): Promise<RatingDto> {
      try {
        const response = await this.conn.get(`/idea/rating/${ideaId}`);
        
        if (response.data.messages?.length)
          throw new Error(response.data.messages.join('\n'));
  
        return response.data.data;
      } catch (error) {
        console.error('Erro ao obter a avaliação da ideia:', error);
        throw error;
      }
    }

    public async deleteDraft(ideaId: string): Promise<void> {
      try {
        const response = await this.conn.delete(`/idea/rating/draft/${ideaId}`);
        
        if (response.data.messages?.length)
          throw new Error(response.data.messages.join('\n'));
      } catch (error) {
        console.error('Erro ao deletar o rascunho:', error);
        throw error;
      }
    }

    public async assignScore(ideaId: string): Promise<RatingDto> {
      try {
        const response = await this.conn.get(`/idea/rating/score/${ideaId}`);
        
        if (response.data.messages?.length)
          throw new Error(response.data.messages.join('\n'));

        return response.data.data;
      } catch (error) {
        console.error('Erro ao atribuir pontuação à ideia:', error);
        throw error;
      }
    }

}

export default RatingProvider;