import React from "react";
import { IdeiaDto } from "../../../../../../../shared/dto/IdeiaDto";
import DimensionProvider from "../../../../../../../shared/provider/DimensionProvider";
import RatingProvider from "../../../../../../../shared/provider/RatingProvider";
import { DimensionDto } from "../../../../../../../shared/dto/DimensionDto";
import RatingDimensionItems from "./ratingDimensionItems";
import { RatingDto } from "../../../../../../../shared/dto/RatingDto";
import { RatingPayload } from "../../..";

interface RatingCriteriasItemsProps {
  idea: IdeiaDto;
  ratingPayload: RatingPayload;
  setRatingPayload: (value: RatingPayload) => void;
  rating: RatingDto;
}

function RatingCriteriasItems({ idea, ratingPayload, setRatingPayload, rating }: RatingCriteriasItemsProps): JSX.Element {
  const [dimensions, setDimensions] = React.useState<DimensionDto[]>([]);

  const ratingProvider = new RatingProvider();
  const dimensionProvider = new DimensionProvider();

  async function getDimensions() {
    try {
      const response = await dimensionProvider.getAll({ page: 1, itemsPerPage: 500 });
      setDimensions(response.data);
    } catch (error) {
      console.error("Erro ao buscar dimensões:", error);
    }
  }

  async function onInit() {
    await getDimensions();
  }

  React.useEffect(() => {
    onInit();
  }, []);

  return (
    <div>
        <RatingDimensionItems dimensions={dimensions} ratingPayload={ratingPayload} setRatingPayload={setRatingPayload} rating={rating} idea={idea}  />  
    </div>
  );
}

export default RatingCriteriasItems;