import React from "react";
import {
	ViewModalContainer,
	ViewModalSection,
	FooterButtons,
	FooterButtonGroup,
	ViewBody,
} from "./styles";
import { useIdeia } from "../../../../shared/context/ideia/provider";
import { ConfigProvider, Modal, Tabs, message } from "antd";
import PermissionValidator from "../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../shared/guard/Permissions";
import { TabItems } from "./constants/tabs";
import DetailsView from "./components/Details";
import DefaultButton from "../../../../shared/util/DefaultButton";
import ptBR from "antd/lib/locale/pt_BR";
import CommentsView from "../NormalView/components/Comments";
import RatingView from "../NormalView/components/Rating";
import RelatedView from "../NormalView/components/Related";
import { RatingDto } from "../../../../shared/dto/RatingDto";
import { RatingPayload } from "../NormalView";
import IdeaProvider from "../../../../shared/provider/IdeaProvider";

export interface UserValue {
	label: string;
	value: string;
}

function ViewModal(): JSX.Element {
	const permissionValidator = new PermissionValidator();
	const {
		openView: openModal,
		setOpenView: setOpenModal,
		ideia,
		setOpenEdit,
	} = useIdeia();
	const ideaProvider = new IdeaProvider();

	React.useEffect(() => {
		if (!permissionValidator.hasPermission(PERMISSIONS.INSERIR_IDEIA)) {
			setOpenModal(false);
		}
	}, []);

	const [tabKey, setTabKey] = React.useState("details");
	const [rating, setRating] = React.useState<RatingDto>({} as RatingDto);
	const [ratingPayload, setRatingPayload] = React.useState<RatingPayload>(
		{} as RatingPayload
	);

	function onInit() {}

	React.useEffect(() => {
		if (openModal) {
			onInit();
		}
	}, [openModal]);

	function onTabChange(key: string) {
		setTabKey(key);
	}

	async function handleDeleteIdea() {
		try {
			await ideaProvider.delete(ideia.ideaId?.toString() || "");
			message.success("Ideia deletada com sucesso!");
			setOpenModal(false);
		} catch (error) {
			message.error("Erro ao deletar ideia.");
		}
	}

	function showDeleteConfirm() {
		Modal.confirm({
			title: 'Você tem certeza que deseja excluir esta ideia?',
			content: 'Esta ação não pode ser desfeita.',
			okText: 'Sim',
			okType: 'danger',
			cancelText: 'Não',
			onOk() {
				handleDeleteIdea();
			},
		});
	}

	return (
		<ViewModalContainer>
			<Modal
				title={<h2>{ideia.title}</h2>}
				className="user-view-modal"
				open={openModal}
				onOk={() => setOpenModal(false)}
				onCancel={() => setOpenModal(false)}
				maskClosable={false}
				width={1280}
				footer={[
					<FooterButtons key="footer-buttons">
						{permissionValidator.hasPermission(PERMISSIONS.REMOVER_IDEIA) && tabKey === "details" && (
							<button
								key={"delete"}
								onClick={showDeleteConfirm}
                className="delete-idea"
								style={{ margin: "6px 10px 6px 0" }}
							>
								Excluir Ideia
							</button>
						)}
							<DefaultButton
								key={"edit"}
								width="150px"
								height="35px"
								fontSize="1em"
								withOutBackground={true}
								params={{
									type: "button",
									onClick: () => setOpenEdit(true),
									style: { margin: "6px 10px 6px 0" },
								}}
							>
								Editar
							</DefaultButton>
							<DefaultButton
								key={"submit"}
								width="150px"
								height="35px"
								fontSize="1em"
								params={{
                  style: { margin: "6px 10px 6px 0" },
									type: "button",
									onClick: () => setOpenModal(false),
								}}
							>
								Fechar
							</DefaultButton>
					</FooterButtons>,
				]}
				centered
			>
				<ConfigProvider locale={ptBR}>
					<ViewModalSection>
						<Tabs
							defaultActiveKey="1"
							items={TabItems}
							style={{ margin: "0 28px"}}
							onChange={onTabChange}
						/>
						<ViewBody>
							{tabKey === "details" && <DetailsView idea={ideia} />}
							{tabKey === "comments" && <CommentsView idea={ideia} />}
							{tabKey === "rating" && (
								<RatingView
									idea={ideia}
									ratingPayload={ratingPayload}
									setRatingPayload={setRatingPayload}
									rating={rating}
								/>
							)}
							{tabKey === "related" && <RelatedView idea={ideia} />}
						</ViewBody>
					</ViewModalSection>
				</ConfigProvider>
			</Modal>
		</ViewModalContainer>
	);
}

export default ViewModal;
