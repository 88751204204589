import React from "react";
import {
	ShowCategoryContainer,
	ShowCategoryCoverImage,
	ShowCategoryDescription,
} from "./styles";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import { CategoryDto } from "../../../../../../../shared/dto/CategoryDto";

function ShowCategory({
												setOpen,
												setEditMode,
												handleDeleteCategoryClick,
												category,
											}: {
	readonly setOpen: React.Dispatch<boolean>;
	readonly setEditMode: React.Dispatch<boolean>;
	readonly handleDeleteCategoryClick: (category: CategoryDto) => void;
	readonly category: CategoryDto;
}) {
	return (
		<ShowCategoryContainer>
			<ShowCategoryCoverImage
				coverImage={category && category.coverImage ? `${process.env.REACT_APP_API_PUBLIC_URL}/${category.coverImage.replace(/\\/g, "/")}` : ""}
			>
				<h2>{category.name}</h2>
			</ShowCategoryCoverImage>

			<ShowCategoryDescription>{category.description}</ShowCategoryDescription>

			<footer>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					withOutBackground={true}
					params={{
						onClick: () => setOpen(false),
					}}
				>
					Voltar
				</DefaultButton>
				<div className="double-button">
					<DefaultButton
						width="150px"
						height="36px"
						fontSize="1em"
						withOutBackground={true}
						params={{
							onClick: () => handleDeleteCategoryClick(category),
						}}
					>
						Excluir categoria
					</DefaultButton>
					<DefaultButton
						width="150px"
						height="36px"
						fontSize="1em"
						params={{
							type: "button",
							onClick: () => setEditMode(true),
						}}
					>
						Editar categoria
					</DefaultButton>
				</div>
			</footer>
		</ShowCategoryContainer>
	);
}

export default ShowCategory;
