import React, { useEffect, useState } from "react";
import {
	StageListContainer,
	StageListHeader,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { StageDto } from "../../../../../../shared/dto/StageDto";
import { IPageData } from "../../../../../../shared/dto/PageData";
import Pagination from "../../../../../../shared/util/Pagination";
import sortList from "../../../../../../shared/util/Order";
import StageProvider from "../../../../../../shared/provider/StageProvider";
import AddStage from "../AddStage";
import DeleteStage from "../DeleteStage";
import { Table } from "antd";
import columns from "./config/columns";
import customLocale from "../../../../../../shared/constants/customLocale";
import mapStageDtoListToItems from "../../utils/mapDtoToItem";
import EditStage from "../EditStage";
import { StageItem } from "../../../../../../shared/dto/StageItem";

function StageListItem({
	openAddStage,
	setOpenAddStage,
}: {
	readonly openAddStage: boolean;
	readonly setOpenAddStage: React.Dispatch<boolean>;
}): JSX.Element {
	const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">();
	const [sortKey, setSortKey] = React.useState<string>();
	const [editStage, setEditStage] = React.useState(false);
	const [deleteStage, setDeleteStage] = React.useState(false);
	const [selectedStageForEdit, setSelectedStageForEdit] = React.useState(
		{} as StageDto
	);
	const [selectedStageForDelete, setSelectedStageForDelete] =
		React.useState({} as StageDto);

	const [response, setResponse] = useState<Array<StageDto>>(
		{} as Array<StageDto>
	);
	const [list, setList] = useState<StageItem[]>([]);

	const stageProvider = new StageProvider();
	const navigate = useNavigate();

	function getPreviousSelectedStageForEdit(data: StageDto[]) {
		if (!data) return;
		const stage = data.find(
			(cat) => cat.stageId === selectedStageForEdit.stageId
		);
		if (stage) setSelectedStageForEdit(stage);
	}

	async function requireStageList() {
		try {
			const data = await stageProvider.getAll();
			if (!data) return;

			setResponse(data);
			setList(mapStageDtoListToItems({
				dto: data,
				deleteFn: handleDeleteStageClick,
				viewFn: handleEditStageClick,
			}));
			getPreviousSelectedStageForEdit(data);
		} catch (error) {
			toast(
				<span>
					<b>Sessão expirada.</b> Você será redirecionado!
				</span>,
				{
					type: "error",
					autoClose: 500,
					onClose: () => {
						navigate("/entrar");
					},
				}
			);
		}
	}

	useEffect(() => {
		requireStageList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleEditStageClick(stage: StageDto) {
		setSelectedStageForEdit(stage);
		setEditStage(true);
	}

	function handleDeleteStageClick(stage: StageDto) {
		setSelectedStageForDelete(stage);
		setDeleteStage(true);
		setEditStage(false);
	}

	function handleSortList() {
		if (sortKey) {
			const direction = sortDirection === "asc" ? "desc" : "asc";
			const sorted = sortList(
				response,
				sortKey as keyof StageDto,
				direction
			);
			setResponse(sorted);
		}
	}

	React.useEffect(() => {
		handleSortList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortDirection, sortKey]);

	return (
		<StageListContainer>
			<AddStage
				open={openAddStage}
				setOpen={setOpenAddStage}
				requireStageList={requireStageList}
			/>
			<EditStage
				open={editStage}
				setOpen={setEditStage}
				requireStageList={requireStageList}
				stage={selectedStageForEdit}
				handleDeleteStageClick={handleDeleteStageClick}
			/>
			<DeleteStage
				open={deleteStage}
				setOpen={setDeleteStage}
				stage={selectedStageForDelete}
				onFinished={requireStageList}
			/>

			<StageListHeader></StageListHeader>

				<Table<StageItem>
					columns={columns}
					dataSource={list || []}
					locale={customLocale}
					pagination={{defaultPageSize: 10}}
					scroll={{ x: 920 }}
					/>
		</StageListContainer>
	);
}

export default StageListItem;
