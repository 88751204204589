import { FaTrash } from "react-icons/fa";
import { CategoryDto } from "../../../../../../../shared/dto/CategoryDto";
import { CategoryItem } from "../../../../../../../shared/dto/CategoryItem";
import { Tooltip } from "antd";

interface MapProps {
  dto: CategoryDto[];
  deleteFn: (dimension: CategoryDto) => void;
  viewFn: (dimension: CategoryDto) => void;
}

function mapCategoryDtoListToItems({ dto, deleteFn, viewFn }: MapProps): CategoryItem[] {
  return dto
    .sort((a, b) => new Date(b.createdAt ?? 0).getTime() - new Date(a.createdAt ?? 0).getTime())
    .map((item) => ({
      ...item,
      action: <>
        <Tooltip title="Deletar categoria">
          <span
            className="list-delete-button"
            onClick={() => deleteFn(item)}
            title="Deletar categoria"
          >
            <FaTrash />
          </span>
        </Tooltip>
        <span
          className="list-editar-button"
          onClick={() => viewFn(item)}
        >
          Visualizar
        </span>
      </>
    }));
}

export default mapCategoryDtoListToItems;