import React from "react";
import {
  LoginBoxContainer,
  LoginBoxContent,
  LoginBoxFooter,
} from "./styles";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../../../../shared/provider/AuthProvider";
import LoginDetach from "../LoginDetach";
import validator from "validator";
import LoginSlide from "../LoginSlide";

function LoginBox(): JSX.Element {
  const navigate = useNavigate();
  const authProvider = new AuthProvider();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userNotFound, setUserNotFound] = React.useState(false);

  React.useEffect(() => {
    if (!open) setUserNotFound(false);
  }, [open]);

  React.useEffect(() => {
    tryRefreshToken();
  }, []);

  React.useEffect(() => {
    if (open) {
      window.localStorage.removeItem("tenantName");
    }
  }, [open]);

  async function tryRefreshToken() {
    try {
      const response = await authProvider.refreshSession();
      if (!response) return false;
      navigate("/");
      return true;
    } catch (error) {
      return false;
    }
  }

  async function handleLogin(
    email: string,
    password: string
  ): Promise<boolean> {
    try {
      setLoading(true);
      if (!validator.isEmail(email)) {
        toast("E-mail inválido", { type: "warning" });
        throw new Error("E-mail inválido");
      }

      if (!password) {
        toast("A Senha não foi inserida", { type: "warning" });
        throw new Error("A Senha não foi inserida");
      }

      const response = await authProvider.login({
        email,
        password,
      });

      setLoading(false);
      setOpen(false);

      toast(
        <>
          <b>Sucesso.</b> Usuário validado com sucesso!
        </>,
        { type: "success", autoClose: 400, onClose: () => navigate("/") }
      );
      return response;
    } catch (error: any) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.messages &&
        error.response.data.messages.length
      ) {
        setUserNotFound(true);
      }
      setLoading(false);
      return false;
    }
  }

  return (
    <LoginBoxContainer>
      <LoginDetach
        open={open}
        setOpen={setOpen}
        loading={loading}
        handleLogin={handleLogin}
        userNotFound={userNotFound}
        setUserNotFound={setUserNotFound}
      />

      <LoginBoxContent>
        <LoginSlide /> {/* Adicionar o componente LoginSlide aqui */}
        <h3>
          Já faz parte da nossa comunidade?
        </h3>

        <p>Entre e cadastre uma ideia!</p>

        <DefaultButton
          marginTop="8%"
          params={{
            onClick: () => setOpen(true),
          }}
        >
          Login
        </DefaultButton>
      </LoginBoxContent>

      <LoginBoxFooter>
      </LoginBoxFooter>
    </LoginBoxContainer>
  );
}

export default LoginBox;
