import React, { useEffect, useState, useCallback } from "react";
import {
	AddCategoryFormContainer,
	AddDivider,
	CategoryCoverImage,
} from "./styles";
import { toast } from "react-toastify";
import CategoryInput from "../../CategoryInput";
import { Upload, UploadFile, message, ConfigProvider, GetProp } from "antd";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import { FaTrash } from "react-icons/fa";
import CategoryProvider from "../../../../../../../shared/provider/CategoryProvider";
import TextArea from "antd/es/input/TextArea";
import { Flex, Spin } from "antd";
import ImgCrop from "antd-img-crop";
import ptBR from "antd/lib/locale/pt_BR";
import { UploadProps } from "antd/lib";
import { ShowCategoryCoverImage } from "../../EditCategory/components/styles";

function AddCategoryForm({
	setOpen,
	isLoading = false,
	setLoading,
	setSuccessMessage,
	requireCategoryList,
}: {
	setOpen: React.Dispatch<boolean>;
	isLoading?: boolean;
	setLoading: React.Dispatch<boolean>;
	setSuccessMessage: React.Dispatch<string>;
	requireCategoryList: () => Promise<void>;
}) {
	const [coverImageUrl, setCoverImageUrl] = useState<string>("");
	const [categoryName, setCategoryName] = useState<string>("");
	const [categoryDescription, setCategoryDescription] = useState<string>("");
	const [fileList, setFileList] = React.useState<UploadFile[]>([]);

	const categoryProvider = new CategoryProvider();

	useEffect(() => {
		if (fileList[0] && fileList[0].status === "done") {
				setCoverImageUrl(fileList[0].url as string);
		}
}, [fileList]);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		createCategory();
	}

	async function createCategory() {
		if (!categoryName || !fileList[0] || !categoryDescription) {
			toast.error("Preencha todos os campos obrigatórios");
			return;
		}

		const file = fileList[0];
		setLoading(true);


		try {
			const result = await categoryProvider.create({
				name: categoryName,
				description: categoryDescription,
			});
			if (result) {
				const { categoryId } = result;
				if (categoryId) {
					await categoryProvider.saveCoverImage(categoryId, file);
				}
			}
			setSuccessMessage(`Categoria "${result.name}" criada com sucesso!`);
			setTimeout(() => requireCategoryList(), 550);
		} catch (error) {
			toast.error("Erro ao criar categoria");
		} finally {
			setLoading(false);
		}
	}

	function deleteLocalCover() {
		setCoverImageUrl("");
		setFileList([]);
	}

	const beforeUpload = (file: File) => {
		const isImage = file.type.startsWith("image/");
		if (!isImage) {
				message.error("Você só pode fazer upload de imagens!");
				return Upload.LIST_IGNORE;
		}
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
				const img = new Image();
				img.src = reader.result as string;
				img.onload = () => {
						const canvas = document.createElement("canvas");
						const ctx = canvas.getContext("2d");
						if (ctx) {
								canvas.width = img.width;
								canvas.height = img.height;
								ctx.drawImage(img, 0, 0);
								canvas.toBlob(
										(blob) => {
												if (blob) {
														const newFile: UploadFile<any> = {
																uid: new Date().getTime().toString(),
																name: file.name,
																status: "done",
																url: URL.createObjectURL(blob),
														};
														setFileList((prevFileList) => [...prevFileList, newFile]);
												}
										},
										file.type
								);
						}
				};
		};
		return false;
	};

	const onChangeFileUpload: UploadProps["onChange"] = ({ fileList: newFileList }) => {
		if (newFileList.length > fileList.length) return;
		setFileList(newFileList);
};

	return (
		<AddCategoryFormContainer onSubmit={handleSubmit}>
			<Flex
				gap={6}
				style={{ marginBottom: 20, flexDirection: "column" }}
			>
				<label
					className={"input-label"}
					htmlFor="title"
				>
					Nome da categoria*:
				</label>
				<TextArea
					id="nome"
					showCount
					placeholder="Digite aqui"
					maxLength={200}
					value={categoryName}
					onChange={(e) => setCategoryName(e.target.value)}
					autoSize={{ minRows: 1, maxRows: 3 }}
				/>
			</Flex>

			<ImgCrop aspect={16 / 9} rotationSlider>
					<Upload
							beforeUpload={beforeUpload}
							listType="picture-card"
							fileList={fileList}
							onChange={onChangeFileUpload}
							maxCount={1}
							showUploadList={false}
					>
							{fileList[0] ? null : <span>Enviar imagem (1200x675)<br />Formatos aceitos: .jpg, .jpeg, .png <br />Tamanho máximo: 5MB</span>}
					</Upload>
			</ImgCrop>

			{!fileList[0] ? (
					<span>Insira uma imagem de capa para esta categoria</span>
			) : (
					<ShowCategoryCoverImage
							coverImage={`${coverImageUrl}`}
					></ShowCategoryCoverImage>
			)}

			<ConfigProvider locale={ptBR}>
				<div id="upload-zone">
					<button
						className="category-delete-cover"
						type="button"
						onClick={deleteLocalCover}
						disabled={!fileList[0]}
						style={{ marginTop: "10px" }}
					>
						{" "}
						<FaTrash /> Excluir capa
					</button>
				</div>
			</ConfigProvider>

			<AddDivider />

			<CategoryInput
				label="Adicione uma descrição (obrigatório)"
				value={categoryDescription}
				setValue={setCategoryDescription}
				placeholder="Digite aqui"
				isLoading={isLoading}
				width="100%"
				height="130px"
				limitCharacters={400}
				type="textarea"
			/>

			<footer>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					withOutBackground={true}
					params={{
						onClick: () => setOpen(false),
					}}
				>
					Voltar
				</DefaultButton>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					params={{
						type: "submit",
						disabled: isLoading,
					}}
				>
					{isLoading ? <Spin /> : "Salvar"}
				</DefaultButton>
			</footer>
		</AddCategoryFormContainer>
	);
}

export default AddCategoryForm;