import * as css from "./styles";
import LoginBox from "../components/LoginBox";
import LoginDetach from "../components/LoginDetach";
import React from "react";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../../../shared/provider/AuthProvider";
import validator from "validator";
import { message } from "antd";

function LoginPage() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userNotFound, setUserNotFound] = React.useState(false);
  const navigate = useNavigate();
  const authProvider = new AuthProvider();

  async function handleLogin(
    email: string,
    password: string
  ): Promise<boolean> {
    try {
      setLoading(true);
      if (!validator.isEmail(email)) {
        message.warning("E-mail inválido");
        throw new Error("E-mail inválido");
      }

      if (!password) {
        message.warning("A Senha não foi inserida");
        throw new Error("A Senha não foi inserida");
      }

      const response = await authProvider.login({
        email,
        password,
      });

      setLoading(false);
      setOpen(false);

      message.success(
        <>
          <b>Sucesso.</b> Usuário validado com sucesso!
        </>,
      );
      navigate("/")
      return response;
    } catch (error: any) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.messages &&
        error.response.data.messages.length
      ) {
        setUserNotFound(true);
      }
      setLoading(false);
      return false;
    }
  }


    return (
        <css.LoginContainer>
            <LoginDetach
                open={open}
                setOpen={setOpen}
                loading={loading}
                handleLogin={handleLogin}
                userNotFound={userNotFound}
                setUserNotFound={setUserNotFound}
            />
            <css.HeaderContainer>
                <div className="header-container">
                    <h2>
                        <img
                            src="/assets/images/logo_login.png"
                            alt="first-logo"
                        />
                    </h2>
                    <LoginBox className="mobile-login-box" open={open} setOpen={setOpen} setUserNotFound={setUserNotFound} />
                    <p>
                        Plataforma aberta de gestão da inovação para estimular a
                        participação dos colaboradores no processo de criação de novas
                        soluções.
                    </p>
                    <br />
                    <p>
                        Um canal para divulgar e compartilhar ideias, permitindo que seus
                        pares possam contribuir, discutir e colaborar para o crescimento até
                        implementação dela.
                    </p>
                    <br />
                    <p>
                        Da mesma forma, você pode ver as outras ideias postadas, interagindo
                        com sua opinião e perguntas para melhorá-las ainda mais!
                    </p>
                    <br />
                    <p>
                        Quer implementar ou melhorar um processo de gestão da inovação em
                        sua organização?
                    </p>
                    <a href="#">Clique aqui para mais informações.</a>
                </div>
                <div className="secund-logo">
                    <img
                        src="/assets/images/slide/marcadataprev.png"
                        alt="second-logo"
                        className="hide-on-mobile"
                    />
                </div>
                <div className="image-light">
                </div>
            </css.HeaderContainer>
            <css.LoginBoxWrapper>
                <img
                    src="/assets/images/stick_man1.png"
                    alt="second-logo"
                    className="hide-on-mobile"
                />
                <img
                    src="/assets/images/stick_man2.png"
                    alt="second-logo"
                    className="hide-on-mobile"
                />
                <LoginBox className="desktop-login-box" open={open} setOpen={setOpen} setUserNotFound={setUserNotFound}  />
                <img
                    src="/assets/images/stick_man3.png"
                    alt="second-logo"
                    className="hide-on-mobile"
                />
                <img
                    src="/assets/images/stick_man4.png"
                    alt="second-logo"
                    className="hide-on-mobile"
                />
            </css.LoginBoxWrapper>
        </css.LoginContainer>
    );
}

export default LoginPage;
