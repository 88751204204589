import React from "react";
import { FooterContainer } from "./styles";
import { Flex } from "antd";
import { FacebookOutlined, InstagramOutlined, TwitterOutlined, LinkedinOutlined, YoutubeOutlined } from '@ant-design/icons';

function Footer() {
    return (
        <FooterContainer>
            <head>
                <div className="logo-footer">
                    <button onClick={() => window.open('https://www.dataprev.gov.br/', '_blank')}>
                        <img
                            src="/assets/images/logodatapreve.png"
                            alt="logotipo da Ideia X"
                        />
                    </button>
                    <Flex className="social-nav" style={{flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <h4>
                            REDES SOCIAIS
                        </h4>
                        <ul>
                            <button onClick={() => window.open('https://www.facebook.com/dataprevtecnologia', '_blank')}>
                                <FacebookOutlined style={{ fontSize: '24px', color: 'white' }} />
                            </button>
                            <button onClick={() => window.open('https://www.instagram.com/dataprev', '_blank')}>
                                <InstagramOutlined style={{ fontSize: '24px', color: 'white' }} />
                            </button>
                            <button onClick={() => window.open('https://x.com/dataprev?mx=2', '_blank')}>
                                <TwitterOutlined style={{ fontSize: '24px', color: 'white' }} />
                            </button>
                            <button onClick={() => window.open('https://www.linkedin.com/company/dataprev-tecnologia/', '_blank')}>
                                <LinkedinOutlined style={{ fontSize: '24px', color: 'white' }} />
                            </button>
                            <button onClick={() => window.open('https://www.youtube.com/user/DataprevTecnologia', '_blank')}>
                                <YoutubeOutlined style={{ fontSize: '24px', color: 'white' }} />
                            </button>
                        </ul>
                    </Flex>
                </div>
            </head>
            <section>
                <nav>
                    <ul>
                        <li>Termos de uso</li>
                        <li>Política de privacidade</li>
                    </ul>
                </nav>
                <span>© IDEIA X {new Date().getFullYear()} - Versão 1.0.0</span>
            </section>
        </FooterContainer>
    );
}

export default Footer;
