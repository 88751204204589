import React from "react";
import { ViewModalContainer, ViewModalSection } from "./styles";
import { ConfigProvider, Modal, Tabs, List, message, Button } from "antd";
import PermissionValidator from "../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../shared/guard/Permissions";
import { TabItems } from "./constants/tabs";
import DetailsView from "./components/Details";
import DefaultButton from "../../../../shared/util/DefaultButton";
import ptBR from 'antd/lib/locale/pt_BR';
import { CommentsView } from "./components/Comments";
import { IdeiaDto } from "../../../../shared/dto/IdeiaDto";
import RatingView from "./components/Rating";
import RatingProvider from "../../../../shared/provider/RatingProvider";
import { CreateRatingItemDTO, RatingDto } from "../../../../shared/dto/RatingDto";
import StageProvider from "../../../../shared/provider/StageProvider";
import { StageDto } from "../../../../shared/dto/StageDto";
import { DeleteOutlined } from "@ant-design/icons";
import RelatedView from "./components/Related";

export interface UserValue {
  label: string;
  value: string;
}

interface IdeiaProps {
  ideia: IdeiaDto;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  fetchStages: () => void;
}

export interface RatingPayload {
  ideaId: string;
  items: CreateRatingItemDTO[];
  type: 'draft' | 'final';
}

function NormalView({ ideia, openModal, setOpenModal, fetchStages: countStages}: IdeiaProps): JSX.Element {
  const permissionValidator = new PermissionValidator();
  const ratingProvider = new RatingProvider();
  const [rating, setRating] = React.useState<RatingDto>({} as RatingDto);
  const [ratingPayload, setRatingPayload] = React.useState<RatingPayload>({} as RatingPayload);
  const [tabKey, setTabKey] = React.useState("details");
  const [openStageModal, setOpenStageModal] = React.useState(false);
  const [stages, setStages] = React.useState<StageDto[]>([]);
  const [currentIdeia, setCurrentIdeia] = React.useState<IdeiaDto>(ideia);

  React.useEffect(() => {
    if (!permissionValidator.hasPermission(PERMISSIONS.ACESSAR_DETALHES_IDEIA)) {
      setOpenModal(false);
    }
  }, []);

  function onInit() {
    setTimeout(() => setTabKey("details"), 200)
    fetchStages();
  }

  async function getRatingByIdeaId(ideaId: string) {
    try {
      const response = await ratingProvider.getRatingByIdeaId(ideaId);
      setRating(response);
    } catch (error) {
      console.error("Erro ao buscar avaliação:", error);
  }
}

  async function deleteDraft(ideaId: string) {
    try {
      await ratingProvider.deleteDraft(ideaId);
      await getRatingByIdeaId(ideaId);
      message.success("Rascunho deletado com sucesso!");
    } catch (error) {
      console.error("Erro ao deletar rascunho:", error);
    }
  }

  async function assignScore(ideaId: string) {
    try {
      const response = await ratingProvider.assignScore(ideaId);
      setRating(response);
    } catch (error) {
      console.error("Erro ao atribuir pontuação:", error);
    }
  }

  async function rateIdea(params: RatingPayload) {
    try {
      const { ideaId, items, type } = params;
      
      if (!ideaId || !items || !items.length) return;

      let response;
      if (type === 'draft') { 
        response = await ratingProvider.saveDraft(ideaId, items);
      }
      if (type === 'final') {
        response = await ratingProvider.saveRating(ideaId, items);
      }
      await assignScore(ideaId);
      if (response) {
        message.success(params.type === 'draft' ? "Rascunho salvo com sucesso!" : "Avaliação salva com sucesso!");
        getRatingByIdeaId(ideaId);
      }
    } catch (error) {
      console.error("Erro ao avaliar ideia:", error);
      
    }
  }
  React.useEffect(() => {
    if (openModal) {
      onInit();
    }
    setRating({} as RatingDto);
  }, [openModal]);

  React.useEffect(() => {
    if (tabKey === "rating") {
      getRatingByIdeaId(ideia.ideaId as string);
    }
  }, [tabKey]);

  async function fetchStages() {
    const stageProvider = new StageProvider();
    try {
      const stages = await stageProvider.getAll();
      setStages(stages);
    } catch (error) {
      console.error("Erro ao obter todas as fases:", error);
    }
  }

  async function changeIdeaStage(ideiaId: string ,stageId: string) {
    const stageProvider = new StageProvider();
    try {
      await stageProvider.changeIdeaStage(ideiaId, stageId);
      message.success("Fase alterada com sucesso!");
      setCurrentIdeia(prevIdeia => ({
        ...prevIdeia,
        stage: stages.find(stage => stage.stageId === stageId)
      }));
      countStages();
      setOpenStageModal(false);
    } catch (error) {
      message.error("Erro ao alterar a fase da ideia.");
    }
  }

  function onTabChange(key: string) {
    setTabKey(key);
  }

  return (
    <ViewModalContainer>
      <Modal
        className="normal-view-modal"
        title={<h2>{currentIdeia.title}</h2>}
        open={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        maskClosable={false}
        width={1280}
        footer={[
          <>
          {tabKey === "rating" && rating?.status === "RASCUNHO" && permissionValidator.hasPermission(PERMISSIONS.AVALIAR_IDEIAS) &&
                <DefaultButton
                  key={"delete"}
                  width="150px"
                  height="35px"
                  fontSize="1em"
                  withOutBackground={true}
                  params={{
                    type: "button",
                    onClick: () => deleteDraft(ideia.ideaId as string),
                    style: { marginRight: "10px" }
                  }}
                >
                  Deletar Rascunho
                </DefaultButton>
              }
          {tabKey === "rating" ? <>
            <DefaultButton
              key={"back"}
              width="150px"
              height="35px"
              fontSize="1em"
              withOutBackground={true}
              params={{
                type: "button",
                onClick: () => rateIdea({...ratingPayload, type: 'draft'}),
                style: { marginRight: "10px" }
              }}
            >
              Salvar Rascunho
            </DefaultButton>
            <DefaultButton
              key={"submit"}
              width="150px"
              height="35px"
              fontSize="1em"
              params={{
                type: "button",
                style: { marginRight: "10px" },
                onClick: () => rateIdea({...ratingPayload, type: 'final'}),
              }} 
            >
              Salvar e Publicar
            </DefaultButton>
          </> :
          <DefaultButton
            key={"back"}
            width="150px"
            height="35px"
            fontSize="1em"
            withOutBackground={true}
            params={{
              type: "button",
              onClick: () => setOpenStageModal(true),
              style: { marginRight: "10px" }
            }}
          >
            Alterar Fase
          </DefaultButton>}
          </>,
          <>
          {!permissionValidator.hasPermission(PERMISSIONS.ALTERAR_FASE_IDEIA) ? null :
            <DefaultButton
              key={"submit"}
              width="150px"
              height="35px"
              fontSize="1em"
              params={{
                type: "button",
                onClick: () => setOpenModal(false),
              }}
            >
              Fechar
          </DefaultButton>
          }
          </>
        ]}
        centered
      >
        <ConfigProvider locale={ptBR}>
          <ViewModalSection>
            <Tabs defaultActiveKey="1" items={TabItems} onChange={onTabChange} activeKey={tabKey} />
            {tabKey === "details" && <DetailsView idea={ideia} />}
            {tabKey === "comments" && <CommentsView idea={ideia} />}
            {tabKey === "rating" && <RatingView idea={ideia} ratingPayload={ratingPayload} setRatingPayload={setRatingPayload} rating={rating} />}
            {tabKey === "related" && <RelatedView idea={ideia} />}
          </ViewModalSection>
        </ConfigProvider>
      </Modal>

      <Modal
        title="Alterar Fase"
        open={openStageModal}
        onOk={() => setOpenStageModal(false)}
        onCancel={() => setOpenStageModal(false)}
        footer={null}
      >
        <List
          dataSource={stages}
          renderItem={stage => (
            <List.Item>
              <Button
                type={stage.stageId === ideia.stage?.stageId ? "primary" : "default"}
                onClick={() => changeIdeaStage(ideia.ideaId as string, stage.stageId as string)}
                style={{ width: "100%" }}
              >
                {stage.title}
              </Button>
            </List.Item>
          )}
        />
      </Modal>
    </ViewModalContainer>
  );
}

export default NormalView;