import { Button, Flex, Tag, Tooltip } from "antd";
import { CardIdeas, CardTitle, CardImage, CardContent } from "./styles";
import { MessageFilled, LikeOutlined, LikeFilled, DislikeOutlined, DislikeFilled, StarOutlined, StarFilled } from "@ant-design/icons";
import { IdeiaDto } from "../../../../shared/dto/IdeiaDto";
import InteractionsProvider from "../../../../shared/provider/InteractionProvider";
import React from "react";
import PermissionValidator from "../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../shared/guard/Permissions";

export interface CardIdeasProps {
    ideia: IdeiaDto;
    handleClick?: (id: string) => void;
}

export const CardIdea: React.FC<CardIdeasProps> = ({ ideia, handleClick }) => {
    const [isFav, setIsFav] = React.useState<boolean>(false);
    const [like, setLike] = React.useState<boolean>(false);
    const [dislike, setDislike] = React.useState<boolean>(false);
    const [likeCount, setLikeCount] = React.useState<number>(0);
    const [dislikeCount, setDislikeCount] = React.useState<number>(0);
    const [favCount, setFavCount] = React.useState<number>(0);
    const permissionValidator = new PermissionValidator();
    
    const interactionProvider = new InteractionsProvider();

    async function handleFavorite(ideaId: string) {
        try {
            const response = await interactionProvider.favorite(ideaId);
            setIsFav(response);
            getFavCount(ideaId);
        } catch (error) {
            console.error("Erro ao favoritar a ideia:", error);
        }
    }

    async function checkIsFav(ideaId: string) {
        try {
            const response = await interactionProvider.isFav(ideaId);
            setIsFav(response);
        } catch (error) {
            console.error("Erro ao verificar favorito:", error);
        }
    }

    async function getInteractionCount(ideaId: string) {
        try {
          const response = await interactionProvider.countInteractions(ideaId);
          if (response) {
            setLikeCount(response.likes);
            setDislikeCount(response.dislikes);
          }
        } catch (error) {
          console.error("Erro ao buscar interações:", error);
        }
      }

      async function wasInteracted(ideaId: string) {
        try {
          const response = await interactionProvider.wasInteracted(ideaId);
          if (response.operation === 'like') {
            setLike(response.operationValue);
            setDislike(false);
          }
        } catch (error) {
          console.error("Erro ao verificar interação:", error);
        }
      }

    async function handleInteraction(ideaId: string, type: 'like' | 'dislike') {
        try {
          const response = await interactionProvider.likeOrDislike(ideaId, type);
          if (response && response.operation === 'like') {
            setLike(response.operationValue);
            setDislike(false);
          }
          if (response && response.operation === 'dislike') {
            setDislike(response.operationValue);
            setLike(false);
          }
    
          setTimeout(() => getInteractionCount(ideaId), 350);
        } catch (error) {
          console.error("Erro ao interagir com a ideia:", error);
        }
      }

      async function getFavCount(ideaId: string) {
        try {
          const response = await interactionProvider.countFavorites(ideaId);
          setFavCount(response);
        } catch (error) {
          console.error("Erro ao buscar favoritos:", error);
        }
      }

    async function onInit() {
        await checkIsFav(ideia.ideaId as string);
        await getInteractionCount(ideia.ideaId as string);
        await getFavCount(ideia.ideaId as string);
        await checkIsFav(ideia.ideaId as string);
        await wasInteracted(ideia.ideaId as string);
    }

    React.useEffect(() => {
        onInit();
    }, []);

    if (!handleClick || !ideia || !ideia.ideaId) {
        return null; // Retorna null em vez de undefined
    } 

    return (
        <CardIdeas >
            <CardImage onClick={ () => handleClick(ideia.ideaId as string) } src={`${process.env.REACT_APP_API_PUBLIC_URL}/${ideia.category?.coverImage}`} alt={"Cover"} />
            <CardContent>
                <CardTitle onClick={ () => handleClick(ideia.ideaId as string) }>{ideia.title}</CardTitle>
                <section onClick={ () => handleClick(ideia.ideaId as string) }>
                    {ideia.shortDescription}
                </section>
                <footer>
                    <Flex className="footer-idea-showcase" gap={6} vertical style={{justifyContent: "space-between"}}>
                        
                        <div>
                            <span style={{fontSize: 12, textTransform: "capitalize"}}>
                                <Tag style={{padding: "0 12px", backgroundColor: "#1351b4", color: "#fff", borderRadius: "12px"}} >
                                    {ideia.category?.name}</Tag>
                                {ideia.stage && <Tag style={{padding: "0 12px", backgroundColor: "#273373", color: "#fff", borderRadius: "12px"}}>
                                  {ideia.stage?.title}</Tag>}
                                    </span>
                        </div>

                        <Flex gap={3} style={{ alignItems: "center", justifyContent: "space-around" }}>
                            <span style={{
                                color: "#273373",
                                fontSize: 12,
                            }}>{ideia.commentCount} <MessageFilled /></span>
                            <div style={{
                                display: !permissionValidator.hasPermission(PERMISSIONS.DAR_LIKE_IDEIA) ? "none" : "flex"
                                }}>
                                <Button
                                    style={{ cursor: "pointer", color: "#1351b4" }}
                                    size={"small"}
                                    type="link"
                                    onClick={() => handleInteraction(ideia.ideaId as string, 'like')}
                                    >{ like ? <LikeFilled /> : <LikeOutlined /> } {likeCount}</Button>
                                </div>
                                <div style={{
                                display: !permissionValidator.hasPermission(PERMISSIONS.DAR_LIKE_IDEIA) ? "none" : "flex"
                                }}>
                                <Button
                                    style={{ cursor: "pointer", color: "#1351b4" }}
                                    size={"small"}
                                    type="link"
                                    onClick={() => handleInteraction(ideia.ideaId as string, 'dislike')}
                                    >{ dislike ? <DislikeFilled /> : <DislikeOutlined /> } {dislikeCount}</Button>
                                </div>
                                <div style={{
                                display: !permissionValidator.hasPermission(PERMISSIONS.SEGUIR_IDEIAS) ? "none" : "flex"
                                }}>
                                <Button
                                    style={{ cursor: "pointer", color: "#1351b4" }}
                                    size={"small"}
                                    type="link"
                                    onClick={() => handleFavorite(ideia.ideaId as string)}
                                    >{ isFav ? <StarFilled /> : <StarOutlined /> } {favCount}</Button>
                                </div>
                        </Flex>

                    </Flex>
                </footer>
            </CardContent>
        </CardIdeas>
    );
};