import React, { useEffect } from "react";
import {
	AddCategoryFormContainer,
	AddDivider,
	CategoryCoverImage,
} from "./styles";
import { toast } from "react-toastify";
import CategoryInput from "../../CategoryInput";
import UploadArea, {
	FileObject,
} from "../../../../../../../shared/util/Upload";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import { FaTrash } from "react-icons/fa";
import CategoryProvider from "../../../../../../../shared/provider/CategoryProvider";
import Cropper, { CropperProps } from "react-easy-crop";
import { cropImageFromFile } from "../../../../../../../shared/util/Upload/tools/cropper";
import TextArea from "antd/es/input/TextArea";
import { Flex } from "antd";

interface CheckboxItem {
	label: string;
	checked: boolean;
}

function AddCategoryForm({
	setOpen,
	isLoading = false,
	setLoading,
	setSuccessMessage,
	requireCategoryList,
}: {
	setOpen: React.Dispatch<boolean>;
	isLoading?: boolean;
	setLoading: React.Dispatch<boolean>;
	setSuccessMessage: React.Dispatch<string>;
	requireCategoryList: () => Promise<void>;
}) {
	const [coverImageUrl, setCoverImageUrl] = React.useState<string>("");
	const [file, setFile] = React.useState<FileObject | null>(null);
	const [categoryName, setCategoryName] = React.useState<string>("");
	const [categoryDescription, setCategoryDescription] =
		React.useState<string>("");

	const categoryProvider = new CategoryProvider();

	useEffect(() => {
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setCoverImageUrl(reader.result as string);
			};
			if (file.data) {
				reader.readAsDataURL(file.data);
			}
		}
	}, [file]);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		createCategory();
	}

	async function createCategory() {
		if (!categoryName || !file || !categoryDescription) {
			toast.error("Preencha todos os campos obrigatórios");
			return;
		}

		setLoading(true);

		const image = await cropImageFromFile(
			file.data as File,
			cropped.x,
			cropped.y,
			cropped.width,
			cropped.height
		);

		try {
			const result = await categoryProvider.create({
				name: categoryName,
				description: categoryDescription,
			});
			if (result) {
				const { categoryId } = result;
				if (categoryId && file.data) {
					await categoryProvider.saveCoverImage(categoryId, image);
				}
			}
			setSuccessMessage(`Categoria "${result.name}" criada com sucesso!`);
			setTimeout(() => requireCategoryList(), 550);
		} catch (error) {
			toast.error("Erro ao criar categoria");
		} finally {
			setLoading(false);
		}
	}

	function deleteLocalCover() {
		setCoverImageUrl("");
		setFile(null);
	}

	const [crop, setCrop] = React.useState({ x: 0, y: 0 });
	const [zoom, setZoom] = React.useState(1);
	const [cropped, setCropped] = React.useState<any>({
		x: 0,
		y: 0,
		width: 0,
		height: 0,
	});

	const onCropComplete = React.useCallback(
		(croppedArea: any, croppedAreaPixels: any) => {
			setCropped(croppedAreaPixels);
		},
		[]
	);

	return (
		<AddCategoryFormContainer onSubmit={handleSubmit}>
			<Flex gap={6} style={{marginBottom: 20, flexDirection: "column" }}>
					<label className={"input-label"} htmlFor="title">
						Nome da categoria*:
					</label>
					<TextArea
							id="nome"
							showCount
							placeholder="Digite aqui"
							maxLength={200}
							value={categoryName}
							onChange={(e) => setCategoryName(e.target.value)}
							autoSize={{ minRows: 1, maxRows: 3 }}
					/>
			</Flex>

			<CategoryCoverImage coverImage={""}>
				{file && coverImageUrl && (
					<Cropper
						image={`${coverImageUrl}`}
						crop={crop}
						zoom={zoom}
						maxZoom={2}
						cropSize={{ width: 620, height: 348.75 }}
						onCropChange={setCrop}
						onZoomChange={setZoom}
						onCropComplete={onCropComplete}
						objectFit="horizontal-cover"
						style={{
							cropAreaStyle: {
								background: "none",
							},
						}}
					/>
				)}

				{!coverImageUrl && (
					<span>Insira uma imagem de capa para esta categoria</span>
				)}
			</CategoryCoverImage>

			<section className="category-upload-footer">
				<div id="upload-zone">
					<p className="input-label">Selecione a foto de capa (obrigatório)</p>
					<UploadArea
						file={file}
						setFile={setFile}
						text="Clique aqui ou arraste e solte o arquivo"
					/>
				</div>

				<button
					className="category-delete-cover"
					type="button"
					onClick={deleteLocalCover}
					disabled={!file}
				>
					{" "}
					<FaTrash /> Excluir capa
				</button>
			</section>

			<AddDivider />

			<CategoryInput
				label="Adicione uma descrição (obrigatório)"
				value={categoryDescription}
				setValue={setCategoryDescription}
				placeholder="Digite aqui"
				isLoading={isLoading}
				width="100%"
				height="130px"
				limitCharacters={400}
				type="textarea"
			/>

			<footer>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					withOutBackground={true}
					params={{
						onClick: () => setOpen(false),
					}}
				>
					Voltar
				</DefaultButton>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					params={{
						type: "submit",
					}}
				>
					Salvar
				</DefaultButton>
			</footer>
		</AddCategoryFormContainer>
	);
}

export default AddCategoryForm;
