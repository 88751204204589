import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  padding: 0 7rem;
  justify-content: space-between; 
  align-items: center; 
  height: 100vh; 
  background-image: url('/assets/images/slide/backgroundLogin.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

export const HeaderContainer = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;

  h2 {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    font-size: 1.4em;

    @media(max-width: 750px) {
      margin-bottom: 65px;
    }
  }

  p {
    margin-top: 10px;
    text-align: justify;
    line-height: 2;
  }

  a {
    text-align: justify;
    color: #000;
    text-decoration: underline; 
    font-weight: bold; 
  }
`;

export const LoginBoxWrapper = styled.div`
  width: 703px;
  display: flex;
  justify-content: flex-end; /* Centraliza o LoginBox */
  align-items: center; /* Centraliza o LoginBox verticalmente */

  @media screen and (max-width: 800px) {
    justify-content: center; /* Centraliza o LoginBox em telas menores */
  }
`;

export const LoginBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza o conteúdo verticalmente */
  flex: 1;
  min-width: 300px;
  max-width: 600px;

  @media(max-width: 750px) {
    margin: 0 20px;
  }
`;

export const LoginBoxHeader = styled.section`
  h2 {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    margin-top: 100px;
    font-size: 1.4em;

    @media(max-width: 750px) {
      margin-bottom: 65px;
    }
  }
`;

export const LoginBoxContent = styled.section`
  text-align: center;
  h3 {
    font-size: 1.5em;
    line-height: 2em;
    transition: all 500ms ease-in-out;
  }
  p {
    margin-top: 5%;
    transition: all 500ms ease-in-out;
    font-size: 1.1em;
  }

  img {
    width: 100%; 
    max-width: 300px; 
    height: auto;
  }
  
  @media(max-width: 500px) {
    h3 {
      font-size: 1.2em;
    }
    p {
      font-size: 1em;
    }
  }
`;

export const LoginBoxFooter = styled.section`
  display: flex;
  gap: 4%;
  margin: 5% 0;
  font-size: 1.1em;
  transition: all 500ms ease-in-out;
  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }

  @media(max-width: 750px) {
    margin-top: 100px;
  }

  @media(max-width: 500px) {
    font-size: 1em;
  }
`;