import styled from "styled-components";

export const ViewModalContainer = styled.div`
  .user-view-modal {
    .ant-modal-content {
      height: 100% !important;
      max-height: 600px !important;
    }
  }
`;

export const ViewModalSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-right: 5px;
  max-height: 600px;
  overflow-y: auto;

  label {
    font-size: 14.4px;
    margin-bottom: 3px;
  }
`;

export const ViewImage = styled.div<{ src: string }>`
  width: 100%;
  height: 300px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ViewBody = styled.div`
  overflow-y: auto;
  min-height: 300px;
  padding: 0 28px;
`;

export const ViewMetaInfo = styled.div``;

export const ViewH2 = styled.h2`
  margin-bottom: 22px;
`;

export const ViewH4 = styled.h4`
  margin-bottom: 22px;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .delete-idea{

    width: 150px;
    height: 35px;
    font-size: 1em;
    background-color: #ff4d4f;
    color: #fff;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;


    &:hover {
      background-color: #fff;
      color: #ff4d4f;
      border: 1px solid #ff4d4f;
    }
  }
`;

export const FooterButtonGroup = styled.div`
  display: flex;
  width: 20rem;
  gap: 10px;
`;