import styled from "styled-components";

export const RelatedContainer = styled.div`
	.search-container {
		display: flex;
        column-gap: .5rem;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;

		@media (max-width: 600px) {
			flex-direction: column;
		}
	}
`;
