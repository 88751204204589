import { Avatar, ConfigProvider, Divider, Flex, Image, List, Tag, Tooltip } from "antd";
import React from "react";
import { IdeiaDto } from "../../../../../../shared/dto/IdeiaDto";
import { ViewH2, ViewH4, ViewMetaInfo } from "../../styles";
import ptBR from 'antd/lib/locale/pt_BR'
import { useIdeia } from "../../../../../../shared/context/ideia/provider";

interface DetailsViewProps {
  idea: IdeiaDto
}

function DetailsView({ idea }: Readonly<DetailsViewProps>) {
  const { images } = useIdeia();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <ViewMetaInfo>
          {idea.category && <Tooltip title="Categoria" placement="top">
              <Tag style={{padding: "0 12px", backgroundColor: "#273373", color: "#fff", borderRadius: "12px"}} >{idea.category.name}</Tag>
            </Tooltip>}
          {idea.stage && <Tooltip title="Fase" placement="top">
              <Tag style={{padding: "0 12px", backgroundColor: "#328bae", color: "#fff", borderRadius: "12px"}}>{idea.stage.title}</Tag>
            </Tooltip>}
        </ViewMetaInfo>
        <div style={{ padding: '0 12px', fontWeight: 'bold', backgroundColor: "#808080", color: "#fff", borderRadius: "12px"}}>
          #{idea.ideaId?.toString().padStart(3, '0')}
        </div>
      </div>

      <Flex gap={15} style={{flexDirection:'column', marginTop: 20}}>
        <div>
          <ViewH2>Problema identificado</ViewH2>
          <div dangerouslySetInnerHTML={{__html: idea.reportedProblem}} />
          <Divider />
        </div>

        <div>
          <ViewH2>Proposta de solução</ViewH2>
          <div dangerouslySetInnerHTML={{__html: idea.solution}} />
          <Divider />
        </div>

         <ConfigProvider locale={ptBR}>
          <Flex gap={6}>
            <Image.PreviewGroup>
                {images.length > 0 && images.map((pic) => (
                  <Image key={pic.id} width={200} src={`${process.env.REACT_APP_API_PUBLIC_URL}/${pic.url}?t${Date.now()}`} />
                ))}
              </Image.PreviewGroup>
          </Flex>
         </ConfigProvider>

        <div>
          <ViewH4>Público alvo</ViewH4>
          {idea.targetAudience}
          <Divider />
        </div>

        {!!idea.coAuthors?.length && <div>
          <ViewH4>Coautores</ViewH4>
          <List
            dataSource={idea.coAuthors}
            renderItem={(item) => (
              <List.Item key={item.email}>
                <List.Item.Meta
                  avatar={<Avatar />}
                  title={<a href="#">{item.name}</a>}
                  description={item.email}
                />
              </List.Item>
            )}
          />
          <Divider />
        </div>}
      </Flex>
    </div>
  );
}

export default DetailsView;