import { Avatar, Button, ConfigProvider, Divider, Flex, Image, List, Tag, Tooltip } from "antd";
import React from "react";
import { IdeiaDto } from "../../../../../../shared/dto/IdeiaDto";
import { ViewH2, ViewH4 } from "../../styles";
import ptBR from 'antd/lib/locale/pt_BR'
import { useIdeia } from "../../../../../../shared/context/ideia/provider";
import PermissionValidator from "../../../../../../shared/guard/PermissionValidator";
import InteractionsProvider from "../../../../../../shared/provider/InteractionProvider";
import { LikeOutlined, LikeFilled, DislikeOutlined, DislikeFilled, StarOutlined, StarFilled } from "@ant-design/icons";
import { PERMISSIONS } from "../../../../../../shared/guard/Permissions";

interface DetailsViewProps {
  idea: IdeiaDto
}

function DetailsView({ idea }: Readonly<DetailsViewProps>) {
  const { images } = useIdeia();
  const [isFav, setIsFav] = React.useState<boolean>(false);
  const [like, setLike] = React.useState<boolean>(false);
  const [dislike, setDislike] = React.useState<boolean>(false);
  const [likeCount, setLikeCount] = React.useState<number>(0);
  const [dislikeCount, setDislikeCount] = React.useState<number>(0);
  const [favCount, setFavCount] = React.useState<number>(0);

  const permissionValidator = new PermissionValidator();
  
  const interactionProvider = new InteractionsProvider();

  async function handleFavorite(ideaId: string) {
      try {
          const response = await interactionProvider.favorite(ideaId);
          setIsFav(response);
      } catch (error) {
          console.error("Erro ao favoritar a ideia:", error);
      }
  }

  async function checkIsFav(ideaId: string) {
      try {
          const response = await interactionProvider.isFav(ideaId);
          setIsFav(response);
      } catch (error) {
          console.error("Erro ao verificar favorito:", error);
      }
  }

  async function getInteractionCount(ideaId: string) {
      try {
        const response = await interactionProvider.countInteractions(ideaId);
        if (response) {
          setLikeCount(response.likes);
          setDislikeCount(response.dislikes);
        }
      } catch (error) {
        console.error("Erro ao buscar interações:", error);
      }
    }

    async function wasInteracted(ideaId: string) {
      try {
        const response = await interactionProvider.wasInteracted(ideaId);
        if (response.operation === 'like') {
          setLike(response.operationValue);
          setDislike(false);
        }
      } catch (error) {
        console.error("Erro ao verificar interação:", error);
      }
    }

  async function handleInteraction(ideaId: string, type: 'like' | 'dislike') {
      try {
        const response = await interactionProvider.likeOrDislike(ideaId, type);
        if (response && response.operation === 'like') {
          setLike(response.operationValue);
          setDislike(false);
        }
        if (response && response.operation === 'dislike') {
          setDislike(response.operationValue);
          setLike(false);
        }
  
        setTimeout(() => getInteractionCount(ideaId), 350);
      } catch (error) {
        console.error("Erro ao interagir com a ideia:", error);
      }
    }

    async function getFavCount(ideaId: string) {
      try {
        const response = await interactionProvider.countFavorites(ideaId);
        setFavCount(response);
      } catch (error) {
        console.error("Erro ao buscar favoritos:", error);
      }
    }

  async function onInit() {
      await checkIsFav(idea.ideaId as string);
      await getInteractionCount(idea.ideaId as string);
      await getFavCount(idea.ideaId as string);
      await checkIsFav(idea.ideaId as string);
      await wasInteracted(idea.ideaId as string);
  }

  React.useEffect(() => {
    onInit();
  });

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Flex vertical gap={6}>
          <div>
            {idea.category && <Tooltip title="Categoria" placement="top">
                <Tag style={{padding: "0 12px", backgroundColor: "#273373", color: "#fff", borderRadius: "12px"}} >{idea.category.name}</Tag>
              </Tooltip>}
            {idea.stage && <Tooltip title="Fase" placement="top">
                <Tag style={{padding: "0 12px", backgroundColor: "#328bae", color: "#fff", borderRadius: "12px"}}>{idea.stage.title}</Tag>
              </Tooltip>}
          </div>

          <Flex gap={3} style={{ alignItems: "center", justifyContent: "space-around" }}>
              <Tooltip title="Gostei" style={{
                  display: !permissionValidator.hasPermission(PERMISSIONS.DAR_LIKE_IDEIA) ? "none" : "flex"
                  }}>
                  <Button
                      style={{ color: "#1351b4" }}
                      size={"middle"}
                      type="link"
                      onClick={() => handleInteraction(idea.ideaId as string, 'like')}
                      >{ like ? <LikeFilled /> : <LikeOutlined /> } {likeCount}</Button>
                  </Tooltip>
                  <Tooltip title="Não gostei" style={{
                  display: !permissionValidator.hasPermission(PERMISSIONS.DAR_LIKE_IDEIA) ? "none" : "flex"
                  }}>
                  <Button
                      style={{ color: "#1351b4" }}
                      size={"middle"}
                      type="link"
                      onClick={() => handleInteraction(idea.ideaId as string, 'dislike')}
                      >{ dislike ? <DislikeFilled /> : <DislikeOutlined /> } {dislikeCount}</Button>
                  </Tooltip>
                  <Tooltip title="Favoritar" style={{
                  display: !permissionValidator.hasPermission(PERMISSIONS.SEGUIR_IDEIAS) ? "none" : "flex"
                  }}>
                  <Button
                      style={{ color: "#1351b4" }}
                      size={"middle"}
                      type="link"
                      onClick={() => handleFavorite(idea.ideaId as string)}
                      >{ isFav ? <StarFilled /> : <StarOutlined /> } {favCount}</Button>
                  </Tooltip>
          </Flex>
        </Flex>
        
        <div style={{ padding: '0 12px', fontWeight: 'bold', backgroundColor: "#808080", color: "#fff", borderRadius: "12px"}}>
          #{idea.ideaId?.toString().padStart(3, '0')}
        </div>
      </div>
      

      <Flex gap={15} style={{flexDirection:'column', marginTop: 20}}>
        <div>
          <ViewH2>Problema identificado</ViewH2>
          <div dangerouslySetInnerHTML={{__html: idea.reportedProblem}} />
          <Divider />
        </div>

        <div>
          <ViewH2>Proposta de solução</ViewH2>
          <div dangerouslySetInnerHTML={{__html: idea.solution}} />
          <Divider />
        </div>

         <ConfigProvider locale={ptBR}>
          <Flex gap={6}>
            <Image.PreviewGroup>
                {images.length > 0 && images.map((pic) => (
                  <Image key={pic.id} width={200} src={`${process.env.REACT_APP_API_PUBLIC_URL}/${pic.url}?t${Date.now()}`} />
                ))}
              </Image.PreviewGroup>
          </Flex>
         </ConfigProvider>

        <div>
          <ViewH4>Público alvo</ViewH4>
          {idea.targetAudience}
          <Divider />
        </div>

        {!!idea.coAuthors?.length && <div>
          <ViewH4>Coautores</ViewH4>
          <List
            dataSource={idea.coAuthors}
            renderItem={(item) => (
              <List.Item key={item.email}>
                <List.Item.Meta
                  avatar={<Avatar />}
                  title={<a href="#">{item.name}</a>}
                  description={item.email}
                />
              </List.Item>
            )}
          />
          <Divider />
        </div>}
      </Flex>
    </div>
  );
}

export default DetailsView;