import styled from "styled-components";

export const LoginBoxContainer = styled.div`
  @media(max-width: 750px) {
    margin: 0 20px;
  }
`;

export const LoginBoxHeader = styled.section`
  h2 {
    display: inline-flex;
    align-items: center;
    font-size: 1.4em;
    @media(max-width: 750px) {
      margin-bottom: 65px;
    }
  }
`;

export const LoginBoxContent = styled.section`
text-align: center;
margin-top: 50px;
  h3 {
    font-size: 1.5em;
    transition: all 500ms ease-in-out;
  }
  p {
    transition: all 500ms ease-in-out;
    font-size: 1.1em;
  }

  img {
    width: 100%; 
    max-width: 300px; 
    height: auto;
  }
  
  @media(max-width: 800px) {
    h3 {
      font-size: 1.2em;
    }
    p {
      font-size: 1em;
    }
  }
`;

export const LoginBoxFooter = styled.section`
  display: flex;
  font-size: 1.1em;
  transition: all 500ms ease-in-out;
  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }

  @media(max-width: 750px) {
    margin-top: 100px;
  }

  @media(max-width: 500px) {
    font-size: 1em;
  }
`;