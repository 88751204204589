import React, { useEffect } from "react";
import {
	EditCategoryFormContainer,
	EditDivider,
	CategoryCoverImage,
} from "./styles";
import { toast } from "react-toastify";
import CategoryInput from "../../CategoryInput";
import UploadArea, {
	FileObject,
} from "../../../../../../../shared/util/Upload";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import { FaTrash } from "react-icons/fa";
import CategoryProvider from "../../../../../../../shared/provider/CategoryProvider";
import { CategoryDto } from "../../../../../../../shared/dto/CategoryDto";
import Cropper from "react-easy-crop";
import "react-easy-crop/react-easy-crop.css";
import { cropImageFromFile } from "../../../../../../../shared/util/Upload/tools/cropper";
import TextArea from "antd/es/input/TextArea";
import { Flex } from "antd";

function EditCategoryForm({
	setOpen,
	isLoading = false,
	setLoading,
	setSuccessMessage,
	requireCategoryList,
	category,
	setEditMode,
}: {
	readonly setOpen: React.Dispatch<boolean>;
	readonly isLoading?: boolean;
	readonly setLoading: React.Dispatch<boolean>;
	readonly setSuccessMessage: React.Dispatch<string>;
	readonly requireCategoryList: () => Promise<void>;
	readonly category: CategoryDto;
	readonly setEditMode: React.Dispatch<boolean>;
}) {
	const [coverImageUrl, setCoverImageUrl] = React.useState<string>(
		category.coverImage ?? ""
	);
	const [file, setFile] = React.useState<FileObject | null>(null);
	const [categoryName, setCategoryName] = React.useState<string>(
		category.name ?? ""
	);
	const [categoryDescription, setCategoryDescription] = React.useState<string>(
		category.description ?? ""
	);

	const categoryProvider = new CategoryProvider();

	useEffect(() => {
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setCoverImageUrl(reader.result as string);
			};
			if (file.data) {
				reader.readAsDataURL(file.data);
			}
		}
	}, [file]);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		updateCategory();
	}

	async function updateCategory() {
		if (!categoryName || !categoryDescription) {
			toast.error("Preencha todos os campos obrigatórios");
			return;
		}
		if (!category.coverImage && !file) {
			toast.error("Insira uma imagem de capa");
			return;
		}

		if (
			categoryName === category.name &&
			categoryDescription === category.description &&
			!file
		) {
			toast.info("Nenhuma alteração foi feita");
			return;
		}

		setLoading(true);

		try {
			const result = await categoryProvider.update({
				categoryId: category.categoryId,
				name: categoryName,
				description: categoryDescription,
			});
			if (result && file && file.data) {
				const { categoryId } = result;
				if (categoryId && file.data) {
					await saveCoverImage();
				}
			}
			setSuccessMessage("Alterações salvas com sucesso!");
			setTimeout(() => requireCategoryList(), 550);
		} catch (error) {
			toast.error("Erro ao criar categoria");
		} finally {
			setLoading(false);
		}
	}

	function deleteLocalCover() {
		setCoverImageUrl("");
		setFile(null);
	}

	async function saveCoverImage() {
		if (!category.categoryId || !file) {
			toast.error("Falha ao salvar imagem de capa");
			return;
		}

		if (file.data && file.data.size > 5000000) {
			toast.info("Imagem muito grande. Insira uma imagem de até 5MB");
			return;
		}

		if (file && file.type) {
			const supportedTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (!supportedTypes.includes(file.type)) {
				toast.error(
					"Formato de arquivo não suportado. Por favor, selecione um arquivo JPG, JPEG ou PNG."
				);
				return;
			}
		}

		setLoading(true);

		const image = await cropImageFromFile(
			file.data as File,
			cropped.x,
			cropped.y,
			cropped.width,
			cropped.height
		);

		if (file && image) {
			try {
				await categoryProvider.saveCoverImage(category.categoryId, image);
				toast.success("Imagem de capa salva com sucesso");
				setTimeout(() => requireCategoryList(), 550);
			} catch (error) {
				toast.error("Erro ao salvar imagem de capa");
			} finally {
				setLoading(false);
			}
		} else {
			toast.error("Erro ao salvar imagem de capa");
			setLoading(false);
		}
	}

	const [crop, setCrop] = React.useState({ x: 0, y: 0 });
	const [zoom, setZoom] = React.useState(1);
	const [cropped, setCropped] = React.useState<any>({
		x: 0,
		y: 0,
		width: 0,
		height: 0,
	});

	const onCropComplete = React.useCallback(
		(croppedArea: any, croppedAreaPixels: any) => {
			setCropped(croppedAreaPixels);
		},
		[]
	);

	return (
		<EditCategoryFormContainer onSubmit={handleSubmit}>
		<Flex gap={6} style={{marginBottom: 20, flexDirection: "column" }}>
					<label className={"input-label"} htmlFor="title">
						Nome da categoria*:
					</label>
					<TextArea
							id="nome"
							showCount
							placeholder="Digite aqui"
							maxLength={200}
							value={categoryName}
							onChange={(e) => setCategoryName(e.target.value)}
							autoSize={{ minRows: 1, maxRows: 3 }}
					/>
			</Flex>

			<CategoryCoverImage
				coverImage={
					!file
						? `${process.env.REACT_APP_API_PUBLIC_URL}/${coverImageUrl}`
						: coverImageUrl
				}
			>
				{file && coverImageUrl && (
					<Cropper
						image={`${coverImageUrl}`}
						crop={crop}
						zoom={zoom}
						maxZoom={2}
						cropSize={{ width: 620, height: 348.75 }}
						onCropChange={setCrop}
						onZoomChange={setZoom}
						onCropComplete={onCropComplete}
						objectFit="horizontal-cover"
						style={{
							cropAreaStyle: {
								background: "none",
							},
						}}
					/>
				)}
				{!coverImageUrl && (
					<span>Insira uma imagem de capa para esta categoria</span>
				)}
			</CategoryCoverImage>

			<section className="category-upload-footer">
				<div id="upload-zone">
					<p className="input-label">Selecione a foto de capa (obrigatório)</p>
					<UploadArea
						file={file}
						setFile={setFile}
						text="Clique aqui ou arraste e solte o arquivo"
					/>
				</div>

				<button
					className="category-delete-cover"
					type="button"
					onClick={deleteLocalCover}
					disabled={!file && !coverImageUrl}
				>
					{" "}
					<FaTrash /> Excluir capa
				</button>

				<DefaultButton
					width="90px"
					height="39px"
					fontSize="1em"
					params={{
						type: "button",
						onClick: () => saveCoverImage(),
						disabled: !file,
					}}
				>
					Enviar
				</DefaultButton>
			</section>

			<EditDivider />

			<CategoryInput
				label="Adicione uma descrição (obrigatório)"
				value={categoryDescription}
				setValue={setCategoryDescription}
				placeholder="Digite aqui"
				isLoading={isLoading}
				width="100%"
				height="130px"
				limitCharacters={400}
				type="textarea"
			/>

			<footer>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					withOutBackground={true}
					params={{
						onClick: () => setEditMode(false),
					}}
				>
					Voltar
				</DefaultButton>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					params={{
						type: "submit",
					}}
				>
					Salvar
				</DefaultButton>
			</footer>
		</EditCategoryFormContainer>
	);
}

export default EditCategoryForm;
