// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-item {
    display: flex;
    flex-direction: column;
    color: #000000;
    font-size: 48px;
    margin-top: 0.5rem; 
    margin-bottom: -1rem;
}

.timeline-label {
    font-size: 12px;
    color: #8c8c8c;
}

.timeline-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
}

.comment-container h3 {
    font-weight: bold;
    font-size: 12px;
}

.comment-container p {
    font-size: 14px;
    color: #595959;
}

.reply-button {
    font-size: 12px;
    color: #1890ff;
}

.reply-count {
    font-size: 12px;
    color: #8c8c8c;
}

.editor-container .ant-btn {
    width: auto;
    align-self: flex-end;
    margin-top: 1rem;
}

.editor-container .ant-input-textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
}

.comment-actions{
    display: flex;
    column-gap: 1rem;
    margin: 1rem 0;
}

.comment-actions button {
    font-size: 12px;
    color: #1890ff;
    background: none;
    border: none;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/modules/ideia/components/NormalView/components/Comments/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".timeline-item {\n    display: flex;\n    flex-direction: column;\n    color: #000000;\n    font-size: 48px;\n    margin-top: 0.5rem; \n    margin-bottom: -1rem;\n}\n\n.timeline-label {\n    font-size: 12px;\n    color: #8c8c8c;\n}\n\n.timeline-content {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    width: 100%;\n    border-radius: 5px;\n    background-color: #fff;\n}\n\n.comment-container h3 {\n    font-weight: bold;\n    font-size: 12px;\n}\n\n.comment-container p {\n    font-size: 14px;\n    color: #595959;\n}\n\n.reply-button {\n    font-size: 12px;\n    color: #1890ff;\n}\n\n.reply-count {\n    font-size: 12px;\n    color: #8c8c8c;\n}\n\n.editor-container .ant-btn {\n    width: auto;\n    align-self: flex-end;\n    margin-top: 1rem;\n}\n\n.editor-container .ant-input-textarea {\n    width: 100%;\n    padding: 1rem;\n    border-radius: 5px;\n}\n\n.comment-actions{\n    display: flex;\n    column-gap: 1rem;\n    margin: 1rem 0;\n}\n\n.comment-actions button {\n    font-size: 12px;\n    color: #1890ff;\n    background: none;\n    border: none;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
