const slideList = [
	{
		path: "/assets/images/slide/01.png",
		title: "Gestão da Inovação"
	},
	{
		path: "/assets/images/slide/02.png",
		title: "Captação de Ideias"
	},
	{
		path: "/assets/images/slide/03.png",
		title: "Colaboração"
	},
];

export default slideList;