import React, { useState } from "react";
import {
	HeaderContainer,
	HeaderHead,
	HeaderMenuContainer,
	SideMenuBackdrop,
	SideMenuContainer,
} from "./styles";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../../provider/AuthProvider";
import TabMenu from "./components/TabMenu";
import DefaultButton from "../DefaultButton";
import { IUsuarioSimplificado } from "../../dto/UsuarioSimplificado";
import UserProvider from "../../provider/UserProvider";
import UserCard from "./components/UserCard";
import PermissionValidator from "../../guard/PermissionValidator";
import { PERMISSIONS } from "../../guard/Permissions";
import { Flex } from "antd";
import NotificationBell from "./components/Bell";
import { FaBars, FaTimes } from "react-icons/fa";

function Header(): JSX.Element {
	const [user, setUser] = useState<IUsuarioSimplificado>(
		{} as IUsuarioSimplificado
	);
	const [menuOpen, setMenuOpen] = useState(false);
	const authProvider = new AuthProvider();
	const userProvider = new UserProvider();
	const permissionValidator = new PermissionValidator();

	const navigate = useNavigate();

	async function handleLogout() {
		try {
			await authProvider.logout();
			navigate("/entrar");
		} catch (error) {
			console.error("Erro ao fazer logout:", error);
		}
	}

	async function getUser() {
		try {
			if (!permissionValidator.hasPermission(PERMISSIONS.VISUALIZAR_PERFIL))
				return;
			const user = await userProvider.getOwnProfile();
			setUser(user);
			localStorage.setItem("profileId", user.id);
		} catch (error) {
			console.error("Erro ao buscar usuário:", error);
		}
	}

	function updateSticky() {
		const stickyElement = document.getElementById("nav-tab-menu");
		let placeholder = document.getElementById("placeholder");

		if (!stickyElement) return;

		if (window.scrollY > stickyElement.offsetTop) {
			if (!placeholder) {
				placeholder = document.createElement("div");
				placeholder.id = "placeholder";
				if (stickyElement.parentNode)
					stickyElement.parentNode.insertBefore(placeholder, stickyElement);
			}
			placeholder.style.height = `${stickyElement.offsetHeight}px`;
			stickyElement.style.position = "fixed";
			stickyElement.style.top = "0";
			stickyElement.style.right = "0";
			stickyElement.style.left = "0";
		} else {
			if (placeholder && placeholder.parentNode) {
				placeholder.parentNode.removeChild(placeholder);
			}
			stickyElement.style.position = "sticky";
			stickyElement.style.top = "0";
		}
	}

	React.useEffect(() => {
		window.addEventListener("scroll", updateSticky);
		getUser();

		return () => {
			window.removeEventListener("scroll", updateSticky);
		};
	}, []);

	const [currentTenant, setCurrentTenant] = useState<string>("");
	const [hasMultipleProfiles, setHasMultipleProfiles] =
		useState<boolean>(false);

	React.useEffect(() => {
		const tenant = localStorage.getItem("tenantName");
		if (tenant) setCurrentTenant(tenant);
	}, [window.localStorage.getItem("tenantName")]);

	React.useEffect(() => {
		const multipleProfiles = localStorage.getItem("multipleProfiles");
		if (multipleProfiles) setHasMultipleProfiles(multipleProfiles === "true");
	}, [window.localStorage.getItem("multipleProfiles")]);

	return (
		<HeaderContainer>
			<HeaderHead>
				<Flex
					className="logo-container"
					style={{ flexDirection: "column", position: "relative" }}
				>
					<div
						className="header-gov-logo"
						onClick={() => (window.location.href = "/")}
					/>
					<div className="header-logo-text">
						<FaBars
							className="hamburger-menu"
							onClick={() => setMenuOpen((prev) => !prev)}
							style={{
								color: "#1351b4",
								cursor: "pointer",
								fontSize: "1.5rem",
							}}
						/>
						<p>Plataforma de inovação</p>
					</div>
					<SideMenuContainer open={menuOpen}>
						<ul>
							<li className="side-menu-close-button">
								<FaTimes onClick={() => setMenuOpen(false)} />
							</li>
							<TabMenu />
						</ul>
					</SideMenuContainer>
				</Flex>

				<Flex
					gap={25}
					style={{ alignItems: "center" }}
				>
					{user &&
						user.name &&
						permissionValidator.hasPermission(
							PERMISSIONS.VISUALIZAR_PERFIL
						) && (
							<>
								<UserCard user={user} />
								{permissionValidator.hasPermission(
									PERMISSIONS.RECEBER_NOTIFICACOES
								) && (
										<div
											style={{
												position: "relative",
												display: "flex",
												alignItems: "center",
											}}
										>
											<NotificationBell user={user} />
										</div>
									)}
							</>
						)}
					<span onClick={handleLogout}>Sair</span>
				</Flex>
			</HeaderHead>

			<HeaderMenuContainer>
				<TabMenu/>		
					<section>
					{currentTenant &&
						currentTenant !== null &&
						currentTenant !== "undefined" && (
							<>
								<span>{currentTenant ? currentTenant : ""}</span>
								{hasMultipleProfiles && (
									<DefaultButton
										width="150px"
										height="35px"
										fontSize="1em"
										withOutBackground={true}
										params={{
											onClick: () => {
												localStorage.removeItem("tenantName");
												window.location.reload();
											},
										}}
									>
										Trocar perfíl
									</DefaultButton>
								)}
							</>
						)}
				</section>
			</HeaderMenuContainer>

			<SideMenuBackdrop
				open={menuOpen}
				onClick={() => setMenuOpen(false)}
			/>
		</HeaderContainer>
	);
}

export default Header;
