import { InteractionCountDto } from '../dto/InteractionCountDto';
import Envoy from '../util/Envoy';

export interface IInteractionDTO {
  ideaId: number;
  profileId: number;
  operation: 'like' | 'dislike';
  operationValue: boolean;
}

class InteractionsProvider extends Envoy {
  public async likeOrDislike(ideaId: string, op: 'like' | 'dislike'): Promise<IInteractionDTO> {
    try {
      const response = await this.conn.get(`/idea/interactions/${ideaId}?op=${op}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao interagir com a ideia:', error);
      throw error;
    }
  }

  public async wasInteracted(ideaId: string): Promise<IInteractionDTO> {
    try {
      const response = await this.conn.get(`/idea/interactions/wasInteracted/${ideaId}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao verificar interação:', error);
      throw error;
    }
  }

  public async favorite(ideaId: string): Promise<boolean> {
    try {
      const response = await this.conn.get(`/idea/favorite/${ideaId}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao favoritar a ideia:', error);
      throw error;
    }
  }

  public async isFav(ideaId: string): Promise<boolean> {
    try {
      const response = await this.conn.get(`/idea/favorite/isFav/${ideaId}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao verificar se a ideia é favorita:', error);
      throw error;
    }
  }

  public async countInteractions(ideaId: string): Promise<InteractionCountDto> {
    try {
      const response = await this.conn.get(`/idea/interactions/count/${ideaId}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao contar interações:', error);
      throw error;
    }
  }

  public async countFavorites(ideaId: string): Promise<number> {
    try {
      const response = await this.conn.get(`/idea/favorite/count/${ideaId}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao contar favoritos:', error);
      throw error;
    }
  }
}

export default InteractionsProvider;