import styled from "styled-components";

export const StageListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  li {
    display: flex;
    min-width: 90px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
  }
`