import styled from "styled-components";

export const RoleListContainer = styled.div`
  margin: 2% 0;
  @media(max-width: 850px) {
    overflow: scroll;
  }
`;

export const RoleListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 500;
  }
  div {
    display: flex;
  }

`;
export const RoleListContent = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 850px;
  
  tbody {
    flex-direction: column;
  }

  tbody, thead {
    display: flex;
    td {
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 0.833em;
    }
    td[data-name="profileName"] {
      flex-grow: 3;
      width: 100%;
      min-width: 250px;
      span {
        max-width: 200px;
        width: 100%;
        flex-shrink: 0;
        background: none;
        overflow: hidden; 
        text-overflow: ellipsis;
      }
    }
    td[data-name="userActions"] {
      flex-grow: 1;
      max-width: 150px;
      width: 100%;
    }
    .td-action {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }
    .th-style {
      background-color: ${({ theme }) => theme.colors.lightGray};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }
  }

  .listing-header {
    width: 100%;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGray};
    height: 60px;
    padding: 0 20px;
    th {
      background-color: ${({ theme }) => theme.colors.lightGray};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }
  }
  `;

export const RoleLine = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  height: 60px;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.lightGray};
  transition: background 0.3s ease-in-out;
  .td-action {
    position: relative;
  }

  &:last-child {
    border-bottom: none;
  }

  td {
    transition: background 0.3s ease-in-out;
  }

  &:hover {
      background-color: ${({ theme }) => theme.colors.softGray};
      td {
        background-color: ${({ theme }) => theme.colors.softGray};
      }
  }

  .role-list-delete-button {
    position: absolute;
    left: -30px;
    cursor: pointer;
    margin-right: 15px;
    
    svg {
      background: none;
      fill: ${({ theme }) => theme.colors.primary};
      
    }
  }

  .role-list-editar-button {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    opacity: 0.9;
    background: ${({ theme }) => theme.colors.softGray};
    padding: 5px 15px;
    border-radius: 20px;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
`;