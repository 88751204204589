import { Button, Flex, message, Tag, Tooltip } from "antd";
import { IdeiaDto } from "../../../../../../shared/dto/IdeiaDto";
import { RatingContainer, RatingRight } from "./styles";
import { CardImage } from "../../../CardIdea/styles";
import { LikeOutlined, LikeFilled, DislikeOutlined, DislikeFilled, StarOutlined, StarFilled } from "@ant-design/icons";
import React from "react";
import InteractionsProvider from "../../../../../../shared/provider/InteractionProvider";
import RatingProgressBar from "./components/ratingProgressBar";
import PermissionValidator from "../../../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../../../shared/guard/Permissions";
import RatingCriteriasItems from "./components/ratingCriteriasItems";
import { RatingDto } from "../../../../../../shared/dto/RatingDto";
import { RatingPayload } from "../..";

interface RatingViewProps {
  idea: IdeiaDto;
  ratingPayload: RatingPayload;
  setRatingPayload: (value: RatingPayload) => void;
  rating: RatingDto;
}

function RatingView({ idea, ratingPayload, setRatingPayload, rating }: RatingViewProps): JSX.Element {
  const [isFav, setIsFav] = React.useState<boolean>(false);
  const [like, setLike] = React.useState<boolean>(false);
  const [dislike, setDislike] = React.useState<boolean>(false);
  const [likeCount, setLikeCount] = React.useState<number>(0);
  const [dislikeCount, setDislikeCount] = React.useState<number>(0);
  const [favCount, setFavCount] = React.useState<number>(0);

  const permissionValidator = new PermissionValidator();

  const interactionProvider = new InteractionsProvider();

  async function getInteractionCount(ideaId: string) {
    try {
      const response = await interactionProvider.countInteractions(ideaId);
      if (response) {
        setLikeCount(response.likes);
        setDislikeCount(response.dislikes);
      }
    } catch (error) {
      console.error("Erro ao buscar interações:", error);
    }
  }

  async function wasInteracted(ideaId: string) {
    try {
      const response = await interactionProvider.wasInteracted(ideaId);
      if (response.operation === 'like') {
        setLike(response.operationValue);
        setDislike(false);
      }
    } catch (error) {
      console.error("Erro ao verificar interação:", error);
    }
  }

  async function getFavCount(ideaId: string) {
    try {
      const response = await interactionProvider.countFavorites(ideaId);
      setFavCount(response);
    } catch (error) {
      console.error("Erro ao buscar favoritos:", error);
    }
  }

  async function checkIsFav(ideaId: string) {
    try {
        const response = await interactionProvider.isFav(ideaId);
        setIsFav(response);
    } catch (error) {
        console.error("Erro ao verificar favorito:", error);
    }
  }

  async function onInit() {
    await getInteractionCount(idea.ideaId as string);
    await getFavCount(idea.ideaId as string);
    await checkIsFav(idea.ideaId as string);
    await wasInteracted(idea.ideaId as string);
  }

  async function handleInteraction(ideaId: string, type: 'like' | 'dislike') {
    try {
      const response = await interactionProvider.likeOrDislike(ideaId, type);
      if (response && response.operation === 'like') {
        setLike(response.operationValue);
        setDislike(false);
      }
      if (response && response.operation === 'dislike') {
        setDislike(response.operationValue);
        setLike(false);
      }

      setTimeout(() => getInteractionCount(ideaId), 350);
    } catch (error) {
      console.error("Erro ao interagir com a ideia:", error);
    }
  }

  async function handleFavorite(ideaId: string) {
    try {
        const response = await interactionProvider.favorite(ideaId);
        setIsFav(response);
        message.success(response ? "Ideia favoritada com sucesso!" : "Ideia desfavoritada com sucesso!");
        await getFavCount(ideaId);
    } catch (error) {
        console.error("Erro ao favoritar a ideia:", error);
    }
  }

  const ratingScore = React.useMemo(() => {
    return rating?.score || 0;
  }, [rating]);

  React.useEffect(() => {
    onInit();
  }, []);

  return (
    <RatingContainer>
      <Flex className="head-rating" gap={45}>
        <div>
          <CardImage src={`${process.env.REACT_APP_API_PUBLIC_URL}/${idea.category?.coverImage}`} alt={"Cover"} />
          <Flex align="center" justify="center" gap="12px">
            <Tooltip title="Gostei" style={{
              display: !permissionValidator.hasPermission(PERMISSIONS.DAR_LIKE_IDEIA) ? "none" : "flex"
            }}>
              <Button
                style={{ fontSize: 22, color: "#1351b4" }}
                type="link"
                onClick={() => handleInteraction(idea.ideaId as string, 'like')}
                >{ like ? <LikeFilled /> : <LikeOutlined /> } {likeCount}</Button>
            </Tooltip>
            <Tooltip title="Não gostei" style={{
              display: !permissionValidator.hasPermission(PERMISSIONS.DAR_LIKE_IDEIA) ? "none" : "flex"
            }}>
              <Button
                style={{ fontSize: 22, color: "#1351b4" }}
                type="link"
                onClick={() => handleInteraction(idea.ideaId as string, 'dislike')}
                >{ dislike ? <DislikeFilled /> : <DislikeOutlined /> } {dislikeCount}</Button>
            </Tooltip>
            <Tooltip title="Favoritar" style={{
              display: !permissionValidator.hasPermission(PERMISSIONS.SEGUIR_IDEIAS) ? "none" : "flex"
            }}>
              <Button
                style={{ fontSize: 22, color: "#1351b4" }}
                type="link"
                onClick={() => handleFavorite(idea.ideaId as string)}
                >{ isFav ? <StarFilled /> : <StarOutlined /> } {favCount}</Button>
            </Tooltip>
          </Flex>
        </div>

        <RatingRight>
          <span style={{fontSize: 12, textTransform: "capitalize"}}>
            <Tag style={{
              padding: "0 12px",
              backgroundColor: "#1351b4",
              color: "#fff",
              borderRadius: "12px"}} >
            {idea.category?.name}
            </Tag>
            {!!rating && rating.status === 'RASCUNHO' && (
              <Tag style={{
                padding: "0 12px",
                backgroundColor: "#fba002",
                color: "#fff",
                borderRadius: "12px"}} >
              RASCUNHO
              </Tag>
            )}
          </span>
        
          <RatingProgressBar rating={ratingScore} />
        </RatingRight>

      </Flex>
      <section>
        <RatingCriteriasItems idea={idea} ratingPayload={ratingPayload} setRatingPayload={setRatingPayload} rating={rating}/>
      </section>
    </RatingContainer>
  )
}

export default RatingView;