import { Flex, Tag } from "antd";
import { IdeiaDto } from "../../../../../../shared/dto/IdeiaDto";
import { RatingContainer, RatingRight } from "./styles";
import React from "react";
import RatingProgressBar from "./components/ratingProgressBar";
import RatingCriteriasItems from "./components/ratingCriteriasItems";
import { RatingDto } from "../../../../../../shared/dto/RatingDto";
import { RatingPayload } from "../..";

interface RatingViewProps {
  idea: IdeiaDto;
  ratingPayload: RatingPayload;
  setRatingPayload: (value: RatingPayload) => void;
  rating: RatingDto;
}

function RatingView({ idea, ratingPayload, setRatingPayload, rating }: RatingViewProps): JSX.Element {
  async function onInit() {
  }

  React.useEffect(() => {
    onInit();
  }, []);

  return (
    <RatingContainer>
      <Flex className="head-rating" gap={45}>
        <RatingRight>
          <RatingProgressBar rating={rating} />
        </RatingRight>
      </Flex>
        
      <section>
        <RatingCriteriasItems idea={idea} ratingPayload={ratingPayload} setRatingPayload={setRatingPayload} rating={rating}/>
      </section>
    </RatingContainer>
  )
}

export default RatingView;