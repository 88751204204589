import React, { useEffect, useState } from "react";
import { IdeiaContainer, Button as StyledButton, StyledRangePicker, StyledSelect, StyledInput } from "./styles";
import { Button, ConfigProvider, Flex, message, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useIdeia } from "../../../shared/context/ideia/provider";
import PermissionValidator from "../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../shared/guard/Permissions";
import IdeaProvider, { IIdeiasDTO } from "../../../shared/provider/IdeaProvider";
import StageProvider from "../../../shared/provider/StageProvider";
import { StageDto } from "../../../shared/dto/StageDto";
import StageList from "../components/Stages";
import NormalView from "../components/NormalView";
import { IdeiaDto } from "../../../shared/dto/IdeiaDto";
import IdeaList from "../components/IdeaList";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import CategoryProvider from "../../../shared/provider/CategoryProvider"; 
import { CategoryDto } from "../../../shared/dto/CategoryDto";
import ptBR from 'antd/es/locale/pt_BR';
import { SearchOutlined, FilterOutlined, ClearOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const { Option } = StyledSelect;

function Ideia(): JSX.Element {
  const { setOpenModal, openModal } = useIdeia();
  const permissionValidator = new PermissionValidator();
  const [stages, setStages] = useState<StageDto[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<IdeiaDto[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedStages, setSelectedStages] = useState<number[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
  const [dateRange, setDateRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
  const ideaProvider = new IdeaProvider();
  const stageProvider = new StageProvider();
  const [openView, setOpenView] = useState(false);
  const [ideia, setIdeia] = useState<IdeiaDto>({} as IdeiaDto);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const categoryProvider = new CategoryProvider();
  const [categories, setCategories] = useState<CategoryDto[]>([] as CategoryDto[]);
  const location = useLocation();

  async function fetchIdeas({ page: pageParam, stageIds, startDate, endDate, categoriesId }: IIdeiasDTO) {
    if (page === 1) {
      setSearchResults([]);
    }
    if (pageParam) setPage(pageParam);

    try {
      const ideasWithCategory = await ideaProvider.searchIdeas({
        page: pageParam || page,
        itemsPerPage: 10,
        query: searchTerm || undefined,
        stageIds: stageIds && stageIds.length ? stageIds : undefined,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        categoriesId: categoriesId && categoriesId.length ? categoriesId : undefined
      });

      if (page === 1) {
        setSearchResults(ideasWithCategory.data);
      }

      if (ideasWithCategory.totalPages < page) {
        setHasMore(false);
      }

      if (ideasWithCategory.currentPage) setPage(ideasWithCategory.currentPage + 1);

      return ideasWithCategory.data;
    } catch (error) {
      console.error("Erro ao obter ideias com categoria:", error);
      return [];
    }
  }

  React.useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  async function fetchStages() {
    try {
      const response = await stageProvider.getAll();
      if (response && response.length) {
        setStages(response);
      }
    } catch (error) {
      console.error("Erro ao obter estágios:", error);
    }
  }

  async function fetchCategories() {
    try {
      const response = await categoryProvider.getAll({ page: 1, itemsPerPage: 1000 });
      if (response && response.data.length) {
        setCategories(response.data);
      }
    } catch (error) {
      console.error("Erro ao obter categorias:", error);
    }
  }

  async function onInit() {
    await fetchIdeas({});
    await fetchStages();
    await fetchCategories();
  }

  useEffect(() => {
    if (!openModal) {
      onInit();
    }
  }, [openModal]);

  useEffect(() => {
    localStorage.removeItem("previousParams");
  }, []);

  function handleCreateIdea() {
    setOpenModal(!openModal);
  }

  function handleViewIdea(id: string) {
    const selectedIdea = searchResults.find((i) => i.ideaId === id);
    if (selectedIdea) {
      setIdeia(selectedIdea);
      setOpenView(true);
    }
  }

  function handleOpenView(open: boolean) {
    if (!open) {
      setIdeia({} as IdeiaDto);
      setOpenView(open);
    }
    else {
      setOpenView(open);
    }
  }

  async function handleSearch() {
    try {
      const ideasWithCategory = await fetchIdeas({
        page: 1,
        stageIds: selectedStages,
        startDate: dateRange[0] ? dateRange[0].toISOString() : undefined,
        endDate: dateRange[1] ? dateRange[1].toISOString() : undefined,
        categoriesId: selectedCategory
      });

      setSearchResults(ideasWithCategory);
      setHasMore(true);
    } catch (error) {
      console.error("Erro ao obter ideias com categoria:", error);
      return [];
    }
  }

  const handleClearSearch = async () => {
    setSearchTerm('');
    setSelectedStages([]);
    setDateRange([null, null]);
    setPage(1);
    setSearchResults([]);
    setHasMore(true);
    setShowFilters(false);

    try {
      const ideasWithCategory = await ideaProvider.searchIdeas({
        page: 1,
        itemsPerPage: 10,
        query: '',
      });

      setSearchResults(ideasWithCategory.data);
      if (ideasWithCategory.totalPages < 1) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Erro ao obter ideias com categoria:", error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ideiaId = queryParams.get("ideia");
    if (ideiaId) {
      handleViewIdea(ideiaId);
    }
  }, [location.search]);

  return (
    <IdeiaContainer>
      <NormalView ideia={ideia} openModal={openView} setOpenModal={handleOpenView} fetchStages={fetchStages} />
      <div className="search-container">
        <Flex gap={8} style={{ flexDirection: "column", margin: "0 14px" }}>
          <StageList stages={stages} />
          <Flex gap={8} align="center" style={{flexWrap: "wrap"}}>
            <StyledInput
              className="search-input"
              placeholder="Pesquisar por título"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onPressEnter={handleSearch}
              allowClear
            />
            <Tooltip title="Pesquisar">
              <Button className="search-button" shape="circle" icon={<SearchOutlined />} onClick={handleSearch}>
              </Button>
            </Tooltip>
            <Tooltip title="Filtrar">
              <Button className="search-button" shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilters(!showFilters)}>
              </Button>
            </Tooltip>
            <Tooltip title="Limpar Pesquisa">
              <Button className="search-button" icon={<ClearOutlined />} onClick={handleClearSearch}>
              Limpar
              </Button>
            </Tooltip>
          </Flex>
          {showFilters && (
            <Flex gap={20} style={{ marginTop: "16px" }}>
              <ConfigProvider locale={ptBR}>
                <StyledRangePicker
                  className="date-picker"
                  value={dateRange.map((date) => date ? dayjs(date.toISOString()) : null) as [Dayjs | null, Dayjs | null]}
                  onChange={(dates) => setDateRange(dates ? [dates[0] ? moment(dates[0].toDate()) : null, dates[1] ? moment(dates[1].toDate()) : null] : [null, null])}
                  placeholder={['Data Inicial', 'Data Final']}
                  format="DD/MM/YYYY"
                  />
              </ConfigProvider>
              <StyledSelect
                className="stage-select"
                mode="multiple"
                placeholder="Selecione as fases"
                value={selectedStages}
                onChange={(value) => setSelectedStages(value as number[])}
              >
                {stages.map((stage) => (
                  <Option key={stage.stageId} value={stage.stageId}>
                    {stage.title}
                  </Option>
                ))}
              </StyledSelect>
              <StyledSelect
                className="category-select"
                placeholder="Selecione a categoria"
                mode="multiple"
                value={selectedCategory}
                onChange={(value) => setSelectedCategory(value as number[])}
              >
                {categories.map((category) => (
                  <Option key={category.categoryId} value={category.categoryId}>
                    {category.name}
                  </Option>
                ))}
              </StyledSelect>
            </Flex>
          )}
        </Flex>
      </div>
      <IdeaList ideas={searchResults} setIdeas={setSearchResults} page={page} setPage={setPage} fetchMoreIdeas={fetchIdeas} handleViewIdea={handleViewIdea} hasMore={hasMore} />
      {permissionValidator.hasPermission(PERMISSIONS.INSERIR_IDEIA) && !openModal && (
        <div id="criar-nova-ideia-btn">
          <StyledButton
            className="button-create-idea"
            onClick={handleCreateIdea}
          >
            <div className="icon-container">
              <FontAwesomeIcon
                icon={faPlus}
                className="icon"
                id="plus-icon"
                style={{ fontSize: "28px", color: "#fff", fontFamily: "FontAwesome" }}
              />
              <FontAwesomeIcon
                icon={faLightbulb}
                className="icon"
                id="lightbulb-icon"
                style={{ fontSize: "40px", color: "#fff", fontFamily: "FontAwesome" }}
              />
            </div>
          </StyledButton>
          <span id="criar-nova-ideia-span">{"Criar nova ideia"}</span>
        </div>
      )}
    </IdeiaContainer>
  );
}

export default Ideia;