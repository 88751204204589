import React, { useEffect, useMemo, useState } from "react";
import { Button, Flex, Input, List, message, Timeline, Tooltip } from "antd";
import { DeleteOutlined, SendOutlined, CommentOutlined } from "@ant-design/icons";
import CustomComment from "./CustomComment";
import { IdeiaDto } from "../../../../../../shared/dto/IdeiaDto";
import "./styles.css";
import CommentProvider from "../../../../../../shared/provider/CommentProvider";
import { CommentDto } from "../../../../../../shared/dto/CommentDto";
import { IPageData } from "../../../../../../shared/dto/PageData";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

interface AppComment {
	id: string;
	author: string;
	avatar: string;
	content: JSX.Element;
	replies?: AppComment[];
}

interface CommentListProps {
	comments: CommentDto[];
}

interface CommentsViewProps {
	idea: IdeiaDto;
}

const CommentList = ({ comments }: CommentListProps) => (
	<List
		dataSource={comments}
		header={`${comments.length} ${comments.length > 1 ? "respostas" : "resposta"}`}
		itemLayout="horizontal"
		renderItem={(props) => <CustomComment comment={props} />}
	/>
);

const Editor = ({
	onChange,
	onSubmit,
	submitting,
	value,
	placeholder,
	buttonText,
	fetchComments,
}: {
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onSubmit: () => void;
	submitting: boolean;
	value: string;
	placeholder: string;
	buttonText: string;
	fetchComments: () => void;
}) => (
	<Flex gap={12} style={{ margin: "10px 0" }}>
    <TextArea
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      className="ant-input-textarea"
      maxLength={500}
      autoSize={{ minRows: 1, maxRows: 3 }}
      showCount
    />
    <Tooltip title="Comentar">
      <Button
        onClick={async () => {
          onSubmit();
          fetchComments();
        }}
        icon={<SendOutlined />}
        type='primary'
        shape='circle'
        disabled={submitting}
        className="ant-btn"
      >
      </Button>
    </Tooltip>
  </Flex>
);

export const CommentsView = ({ idea }: CommentsViewProps) => {
	const [commentValue, setCommentValue] = useState("");
	const [replyValue, setReplyValue] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const [comments, setComments] = useState<AppComment[]>([]);
	const [showReplyInput, setShowReplyInput] = useState<number | null>(null);
	const [ideaComments, setIdeaComments] = useState<
		IPageData<Array<CommentDto>>
	>({
		data: [],
		currentPage: 0,
		itemsPerPage: 0,
		totalItems: 0,
		totalPages: 0,
	});
	const [expandedReplies, setExpandedReplies] = useState<{
		[key: number]: boolean;
	}>({});

	const commentsProvider = new CommentProvider();

	const handleSubmit = async () => {
		if (!commentValue || !idea.ideaId) return;

		setSubmitting(true);

		try {
			await commentsProvider.postComment(idea.ideaId, commentValue);
			fetchComments();
			setCommentValue("");
		} catch (error) {
			console.error("Erro ao criar comentário:", error);
		} finally {
			setSubmitting(false);
		}
	};

	const handleReplySubmit = async (index: number) => {
		if (!replyValue || !idea.ideaId || !index) return;

		setSubmitting(true);

		try {
			await commentsProvider.postComment(
				idea.ideaId,
				replyValue,
				index.toString()
			);
			fetchComments();
			setReplyValue("");
			setShowReplyInput(null);
		} catch (error) {
			console.error("Erro ao criar resposta:", error);
		} finally {
			setSubmitting(false);
			fetchComments();
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setCommentValue(e.target.value);
	};

	const handleReplyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setReplyValue(e.target.value);
	};

	const toggleRepliesVisibility = (commentId: number) => {
		setExpandedReplies((prev) => ({
			...prev,
			[commentId]: !prev[commentId],
		}));
	};

	const handleDelete = async (commentId: number) => {
		try {
			await commentsProvider.deleteComment(commentId);
			fetchComments();
      message.success('Comentário apagado com sucesso');
		} catch (error) {
      message.error('Erro ao apagar comentário');
			console.error("Erro ao apagar comentário:", error);
		}
	};

	const comentarios = useMemo(() => {
		return ideaComments.data;
	}, [ideaComments]);

	async function fetchComments() {
		try {
			if (!idea.ideaId) return;
			const response = await commentsProvider.findByIdeiaId(idea.ideaId);

			if (response) {
				setIdeaComments(response);
			}
		} catch (error) {
			console.error("Erro ao obter comentários:", error);
		}
	}

	async function onInit() {
		await fetchComments();
	}

	useEffect(() => {
		onInit();
	}, []);

	const renderComments = (comments: CommentDto[]) => {
		const userId = localStorage.getItem("profileId");

		return comments.map((comment) => {
			if (comment.commentId === null) return null;
			const isRepliesVisible =
				expandedReplies[Number(comment.commentId)] || false;
      const isAuthor = Number(comment.author?.profileId) === Number(userId);
			return (
				<Timeline.Item key={comment.commentId}>
					<div className="timeline-item">
						<div className="timeline-label">
							<span>
								{moment(comment.createdAt).format("DD/MM/YYYY HH:mm")}
							</span>
						</div>
						<div className="timeline-content">
							<div className="comment-container">
								<h3>{comment.author?.name}</h3>
								<p>{comment.content}</p>
								<div className="comment-actions">
									{comment.replies && comment.replies.length > 0 && (
										<button
											style={{ margin: 0 }}
											onClick={() =>
												toggleRepliesVisibility(Number(comment.commentId))
											}
											className="replies-toggle-button"
										>
											{isRepliesVisible
												? "Ocultar respostas"
												: `${comment.replies.length} respostas`}
										</button>
									)}
									<Button
                    type="link"
                    icon={<CommentOutlined />}
										onClick={() => setShowReplyInput(Number(comment.commentId))}
										className="reply-button"
									>
										Responder
									</Button>
									{isAuthor && (
										<Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(Number(comment.commentId))}
                    className='delete-button'
                  />
									)}
								</div>
								{showReplyInput === Number(comment.commentId) && (
									<Editor
										onChange={handleReplyChange}
										onSubmit={() =>
											handleReplySubmit(Number(comment.commentId))
										}
										submitting={submitting}
										value={replyValue}
										placeholder="Digite sua resposta"
										buttonText="Responder"
										fetchComments={fetchComments}
									/>
								)}
								{isRepliesVisible && comment.replies && (
									<div className="replies">
										{renderComments(comment.replies)}
									</div>
								)}
							</div>
						</div>
					</div>
				</Timeline.Item>
			);
		});
	};

	const timelineItems = renderComments(comentarios);

	return (
		<div className="comments-view">
			<Timeline>{timelineItems}</Timeline>
			<Editor
				onChange={handleChange}
				onSubmit={handleSubmit}
				submitting={submitting}
				value={commentValue}
				placeholder="Digite seu comentário"
				buttonText="Comentar"
				fetchComments={fetchComments}
			/>
		</div>
	);
};

export default CommentsView;
